import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allCategories: [],
  noti_counter: 0,
  chat_counter: 0,
  dataOfUserForWhomThePackagePurchasing: null,
};

const commonSlice = createSlice({
  name: "commonSlice",
  initialState,
  reducers: {
    setAllCommon(state, action) {
      state.allCategories = action.payload?.allCategories;
    },

    saveNotiData(state, action) {
      state.noti_counter += action?.payload || 1;
    },
    clearNotiData(state) {
      state.noti_counter = 0;
      state.noti_data = [];
    },
    saveChatCounter(state) {
      state.chat_counter += 1;
    },
    clearChatCounter(state) {
      state.chat_counter = 0;
    },
    setDataOfUserForWhomThePackagePurchasing(state, action) {
      state.dataOfUserForWhomThePackagePurchasing = action.payload;
    },
    clearDataOfUserForWhomThePackagePurchasing(state) {
      state.dataOfUserForWhomThePackagePurchasing = null;
    },
  },
});

export const {
  setAllCommon,
  saveNotiData,
  saveChatCounter,
  clearChatCounter,
  clearNotiData,
  setDataOfUserForWhomThePackagePurchasing,
  clearDataOfUserForWhomThePackagePurchasing
} = commonSlice.actions;

export default commonSlice.reducer;
