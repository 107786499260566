/* eslint-disable */

import React, { useEffect, useState } from "react";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import { Col, Container, Row } from "react-bootstrap";
import classes from "./AllLessons.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { Get } from "../../Axios/AxiosFunctions";
import { useSelector } from "react-redux";
import LottieLoader from "../../Component/LottieLoader/LottieLoader";
import { BaseURL } from "../../config/apiUrl";
import NoData from "../../Component/NoData/NoData";
import LessonCard from "../../Component/LessonCard/LessonCard";

const AllLessons = () => {
  const { courseSlug } = useParams();
  const navigate = useNavigate();
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const [isLoading, setIsLoading] = useState(false);
  const [lessonData, setLessonData] = useState(null);
  const url = BaseURL(`course`);

  // getAllLessonOfCourse
  const getAllLessonOfCourse = async () => {
    const apiUrl = `${url}/lesson/${courseSlug}`;
    setIsLoading(true);
    const response = await Get(apiUrl, accessToken);

    if (response !== undefined) {
      setLessonData(response?.data?.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getAllLessonOfCourse();
  }, []);

  return (
    <div>
      <SideBarSkeleton heading={"Lessons"}>
        <div className={classes?.topContainer}>
          <Container className={classes.main}>
            <Row className={classes.row}>
             
              {lessonData?.length > 0 ? (
                lessonData?.map((item, index) => (
                  <Col md={4} key={`lessson-${index}`}>
                    <LessonCard
                      data={item}
                      onClick={() => {
                        navigate(`/course/${courseSlug}/${item?.slug}`, {
                          state: item?.material,
                        });
                      }}
                    />
                  </Col>
                ))
              ) : (
                !isLoading && <NoData text={"No Lesson Found"} />
              )}
            </Row>
          </Container>
        </div>
      </SideBarSkeleton>

      {isLoading && <LottieLoader />}
    </div>
  );
};

export default AllLessons;
