import React, { useEffect } from "react";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import { Col, Container, Row } from "react-bootstrap";
import classes from "./ComplainTeacher.module.css";
import { DropDown } from "../../Component/DropDown/DropDown";
import { useState } from "react";
import { Input } from "../../Component/Input/Input";
import { TextArea } from "../../Component/TextArea";
import { Button } from "../../Component/Button/Button";
import { Get, Post } from "../../Axios/AxiosFunctions";
import { BaseURL, apiHeader, capitalizeFirstLetter } from "../../config/apiUrl";
import LottieLoader from "../../Component/LottieLoader/LottieLoader";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const ComplainTeacher = () => {
  const { access_token, user } = useSelector((state) => state?.authReducer);
  const [student, setStudent] = useState("");
  const [course, setCourse] = useState("");
  const [teacher, setTeacher] = useState("");
  const [title, setTitle] = useState("");
  const [reason, setReason] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [studentData, setStudentData] = useState([]);
  const coursesData = student?.subscriptions?.map((s) => s?.course);
  const teachersData = student?.subscriptions?.reduce((acc, curr) => {
    if (curr?.teacher?.courses?.includes(course?._id)) {
      acc.push(curr?.teacher);
    }
    return acc;
  }, []);

  // getData
  async function getData() {
    const url = BaseURL(`users/all-childrens`);
    setIsLoading(true);
    const response = await Get(url, access_token);
    setIsLoading(false);
    if (response) {
      const stdData = response?.data?.data;
      setStudentData(stdData);

      if (stdData?.length > 0 && user?.role === "student") {
        setStudent(stdData.find((s) => s._id === user._id));
      }
    }
  }

  useEffect(() => {
    getData();
  }, []);

  // registerComplainHandler
  const registerComplainHandler = async () => {
    const params = {
      complainType: "teacher",
      student: student?._id,
      course: course?._id,
      teacher: teacher?._id,
    };

    for (let key in params) {
      if (!params[key])
        return toast.warn(`Please select a ${capitalizeFirstLetter(key)}.`);
    }

    params.subject = title;
    params.complain = reason;

    if (params.subject.length < 10)
      return toast.warn(`Subject must be at least 10 characters long.`);
    if (params.complain.length < 10)
      return toast.warn(`Complain must be at least 50 characters long.`);

    const url = BaseURL(`complain/create`);

    setIsLoading(true);
    const response = await Post(url, params, apiHeader(access_token));
    setIsLoading(false);

    if (response) {
      toast.success(`Complain registered successfully.`);
      setStudent("");
      setCourse("");
      setTeacher("");
      setTitle("");
      setReason("");
    }
  };

  return (
    <>
      <SideBarSkeleton heading={"Register a Complaint"}>
        <div className={classes?.topContainer}>
          <Container className={classes.main}>
            <Row>
              {user?.role !== "student" && (
                <Col md={4}>
                  <DropDown
                    label={"Select Student"}
                    placeholder={"Select Student"}
                    options={studentData}
                    optionLabel={["firstName", "lastName"]}
                    optionValue={"_id"}
                    setter={(e) => {
                      if (
                        e?.subscriptions.at(-1)?.status == "notPaid" &&
                        !e?.isTrialEnd
                      )
                        return toast.warn(
                          `Your trial class is in pending please attend your trial class first.`
                        );
                      setStudent(e);

                      setCourse("");
                      setTeacher("");
                    }}
                    value={student}
                  />
                </Col>
              )}

              <Col
                md={user?.role === "student" ? 6 : 4}
                onClick={() => {
                  if (
                    user?.role === "student" &&
                    student?.subscriptions.at(-1)?.status == "notPaid" &&
                    !student?.isTrialEnd
                  ) {
                    toast.warn(
                      `Your trial class is in pending please attend your trial class first.`
                    );
                  }
                }}
              >
                <DropDown
                  placeholder={"Select Course"}
                  label={"Select Course"}
                  disabled={
                    !coursesData ||
                    (student?.subscriptions.at(-1)?.status == "notPaid" &&
                      !student?.isTrialEnd)
                  }
                  options={student ? coursesData : []}
                  optionLabel={["title", "category"]}
                  optionValue={"_id"}
                  setter={(e) => {
                    setCourse(e);
                    setTeacher("");
                  }}
                  value={course}
                />
              </Col>
              <Col md={user?.role === "student" ? 6 : 4}>
                <DropDown
                  label={"Select Teacher"}
                  disabled={course ? false : true}
                  placeholder={"Select Teacher"}
                  options={teachersData?.length > 0 ? teachersData : []}
                  optionLabel={["firstName", "lastName"]}
                  optionValue={"_id"}
                  setter={(e) => {
                    setTeacher(e);
                  }}
                  value={teacher}
                />
              </Col>
              <Col md={12}>
                <div className="mt-4">
                  <Input
                    placeholder={"Enter Title"}
                    value={title}
                    setter={setTitle}
                  />
                </div>
              </Col>
              <Col md={12}>
                <div className="mt-4">
                  <TextArea
                    placeholder={"Enter Reason"}
                    value={reason}
                    setter={setReason}
                  />
                </div>
              </Col>
              <Col md={12}>
                <div className="mt-4">
                  <Button
                    className={classes.btn}
                    label={"Submit"}
                    disabled={isLoading}
                    onClick={() => {
                      registerComplainHandler();
                    }}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        {isLoading && <LottieLoader />}
      </SideBarSkeleton>
    </>
  );
};

export default ComplainTeacher;
