import React from "react";
import ModalSkeleton from "../ModalSkeleton";
import classes from "./AnnouncementDetailModal.module.css";
import ShowMoreShowLessText from "../../Component/ShowMoreShowLess/ShowMoreShowLessText";
import moment from "moment";
import { Col, Row } from "react-bootstrap";
const AnnouncementDetailModal = ({ show, setShow, data }) => {
  console.log(data, "data 2123");
  return (
    <div>
      <ModalSkeleton
        setShow={setShow}
        show={show}
        width={"800px"}
        //   header={"Login"}
        header={`Announcement Details`}
        borderRadius={"20px"}
        showCloseIcon
      >
        <div className={classes.main}>
          {/* <div className={classes.header}></div> */}
          <Row className={classes.body}>
            <Col md={6}>
            <p>
              <b>Title: </b>
              {data?.title}
            </p>
            </Col>
            <Col md={6}>
            <p>
              <b>Start Date: </b>
              {moment(data?.startDate).format("DD MMM YYYY")}
            </p>

            </Col>
            <Col md={6}>
            <p>
              <b>End Date: </b>
              {moment(data?.endDate).format("DD MMM YYYY")}
            </p>
           

            </Col>
            <Col md={6}>
            <p>
              <b>Created At: </b>
              {moment(data?.createdAt).format("DD MMM YYYY")}
            </p>
           

            </Col>
            <Col md={12}>
            <p>
              <b className="d-block">Description: </b>
              {
                <ShowMoreShowLessText
                  text={data?.description}
                  visibility={200}
                />
              }{" "}
            </p>
           

            </Col>
            
          </Row>
        </div>
      </ModalSkeleton>
    </div>
  );
};

export default AnnouncementDetailModal;
