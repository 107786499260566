import React from "react";
import ModalSkeleton from "../ModalSkeleton";
import classes from "./SalaryDetailModal.module.css";
import {  Col, Row } from "react-bootstrap";
const SalaryDetailModal = ({ show, setShow,data }) => {

  // const [btnLoading, setBtnLoading] = useState(false)
  return (
    <ModalSkeleton
      show={show}
      setShow={setShow}
      width={"550px"}
      borderRadius={"10px"}
      borderLine={false}
      header={"Salary Detail"}
      showCloseIcon
      // headerClass={classes.header}
    >
      <Row>
        <Col md={6} className={classes.detail}>
          <p>Teacher Name:</p>
          <p>{[data?.teacher?.firstName,data?.teacher?.lastName].join(" ")}</p>
        </Col>
        <Col md={6} className={classes.detail}>
          <p>Amount:</p>
          <p>${data?.amount}</p>
        </Col>
        <Col md={6} className={classes.detail}>
          <p>Hours:</p>
          <p>{data?.hours} hrs</p>
        </Col>
       
        <Col md={6} className={classes.detail}>
          <p>No. Of Classes:</p>
          <p>{data?.classes?.length} Classes</p>
        </Col>
        <Col md={6} className={classes.detail}>
          <p>Status</p>
          <p>{data?.status==="notPaid"?"Unpaid":"Paid"}</p>
        </Col>
      
      </Row>
    </ModalSkeleton>
  );
};

export default SalaryDetailModal;
