/* eslint-disable */

import React, { useRef } from "react";
import { MdPictureAsPdf } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import { AiFillEye } from "react-icons/ai";
import { FaFileUpload } from "react-icons/fa";

import classes from "./UploadMultiplePDF.module.css";
import { toast } from "react-toastify";
import { Button } from "../../Button/Button";

function UploadMultiplePDF({ state, setter, edit = true, isMultiple = true }) {
  const inputRef = useRef(null);
  const HandleUploadFile = (e) => {
    if (isMultiple) {
      let notPDF = false;
      Array.from(e.target.files)?.map((file) => {
        if (!["pdf"].includes(file?.name?.split(".")[1])) {
          return (notPDF = true);
        }
      });
      if (notPDF) {
        return toast.error("Only PDF File Is Allowed");
      }
      setter((pre) => [...pre, ...Array.from(e.target.files)]);
    } else {
      let file = e.target.files[0];
      if (!["pdf"].includes(file?.name?.split(".")[1])) {
        return toast.error("Only PDF File Is Allowed");
      }
      setter(file);
    }
  };
  const HandleDeleteFile = (index) => {
    if (isMultiple) {
      setter((prev) => prev.filter((_, i) => i !== index));
    } else {
      setter(null);
    }
  };

  return (
    <div className={classes.box}>
      {(isMultiple || state == null) && (
        <div
          className={classes.pdfBox}
          onClick={() => {
            edit && inputRef.current.click();
          }}
        >
          <FaFileUpload color={"var(--main-color)"} size={30} />
          <span className={classes?.uploadText}>upload</span>
        </div>
      )}
      {Array.isArray(state)
        ? state.map((file, index) => (
            <div className={classes.pdfBox} key={index}>
              <MdPictureAsPdf className={classes.typeIcon} size={35} />
              {/* On Hover */}
              <div className={classes.viewBtnBox}>
                <Button
                  leftIcon={<AiFillEye />}
                  className={classes.icon}
                  onClick={() => window.open(URL.createObjectURL(file))}
                />
              </div>
              {/* On Hover */}
              {edit && (
                <div className={classes.editAndDelete}>
                  <Button
                    className={classes.icon}
                    onClick={() => HandleDeleteFile(index)}
                    leftIcon={<RiDeleteBinLine />}
                  />
                </div>
              )}
            </div>
          ))
        : state && (
            <div className={classes.pdfBox}>
              <MdPictureAsPdf className={classes.typeIcon} size={35} />
              {/* On Hover */}
              <div className={classes.viewBtnBox}>
                <Button
                  leftIcon={<AiFillEye />}
                  className={classes.icon}
                  onClick={() => window.open(URL.createObjectURL(state))}
                />
              </div>
              {/* On Hover */}
              {edit && (
                <div className={classes.editAndDelete}>
                  <Button
                    className={classes.icon}
                    onClick={() => HandleDeleteFile()}
                    leftIcon={<RiDeleteBinLine />}
                  />
                </div>
              )}
            </div>
          )}

      {/* Input For File Upload */}
      <input
        hidden
        type="file"
        ref={inputRef}
        onChange={HandleUploadFile}
        multiple={isMultiple}
      />
    </div>
  );
}

export default UploadMultiplePDF;
