export function returnColoredStatus(status, statusReplaceText) {
  return ["pending", "accepted"]?.includes(status) ? (
    <div className={"status-pending"}>{statusReplaceText}</div>
  ) : ["completed"].includes(status) ? (
    <div className={"status-completed"}>{statusReplaceText}</div>
  ) : status == "modify-request" ? (
    <div className={"status-modify-request"}>{statusReplaceText}</div>
  ) : ["cancelled", "rejected"].includes(status) ? (
    <div className={"status-deactive"}>{statusReplaceText}</div>
  ) : ["active"].includes(status) ? (
    <div className={"status-active"}>{statusReplaceText}</div>
  ) : (
    <></>
  );
}

export const UploadImageValidtor = (file) => {
  const validImageTypes = [
    // "image/gif",
    "image/jpeg",
    "image/png",
    "image/jpg",
    "image/webp",
  ];
  if (!validImageTypes.includes(file["type"])) {
    return false;
  }
  return true;
};
export const UploadVideoValidtor = (file) => {
  const acceptedTypes = [
    "video/mp4",
    ".mkv",
    ".avi", // .video file extention list
  ];
  if (!acceptedTypes.includes(file["type"])) {
    return false;
  }
  return true;
};
