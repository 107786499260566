import React from "react";
import classes from "./StatsCard.module.css";
import { useNavigate } from "react-router-dom";

const StatsCard = ({ data }) => {
  const navigate = useNavigate();

  return (
    <div
      className={classes.statWrap}
      onClick={() => {
        data?.path && navigate(data?.path);
      }}
    >
      {data?.icon && data?.icon}
      <p>
        {data?.title} {`(${data?.count || 0})`}
      </p>
    </div>
  );
};

export default StatsCard;
