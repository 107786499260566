/* eslint-disable */

import { FaGgCircle, FaMoneyBillAlt, FaUserCircle } from "react-icons/fa";
import { FiPackage } from "react-icons/fi";
import { HiUsers } from "react-icons/hi";
import { RiDashboardFill } from "react-icons/ri";
import { BsFillPeopleFill } from "react-icons/bs";
import { AiFillSchedule } from "react-icons/ai";
import { IoPersonCircleSharp, IoTodaySharp } from "react-icons/io5";
import { GoGitPullRequest, GoVerified } from "react-icons/go";
import { MdAnnouncement } from "react-icons/md";
import { IoMdNotifications } from "react-icons/io";
import { SiMoneygram } from "react-icons/si";

const CreateSideBarMenu = (userRole) => {
  const SideBarMenu = [
    {
      name: "Dashboard",
      icon: <RiDashboardFill size={22} />,
      path:
        userRole == "teacher"
          ? "/teacher/dashboard"
          : userRole == "parent"
          ? "/parent/dashboard"
          : "/student/dashboard",
    },
    userRole == "parent" && {
      name: "Students",
      icon: <BsFillPeopleFill size={22} />,
      path: "/student/all",
    },
    {
      name: "Subscriptions",
      icon: <BsFillPeopleFill size={22} />,
      path: "/subscriptions",
    },
    ["teacher", "parent"].includes(userRole) && {
      name: "Class Requests",
      icon: <GoGitPullRequest size={22} />,
      path: "/class/request",
    },
    {
      name: "Today's Classes",
      icon: <IoTodaySharp size={22} />,
      path: "/class/today",
    },
    {
      name: "Classes",
      icon: <AiFillSchedule size={22} />,
      path: "/class/all",
    },
    {
      name: "All Courses",
      icon: <GoVerified size={22} />,
      path: "/course/all",
    },
    userRole == "teacher" && {
      name: "Salaries",
      icon: <FaMoneyBillAlt size={22} />,
      path: "/salaries",
    },
    userRole !== "teacher" && {
      name: "Complain",
      icon: <FaGgCircle size={22} />,
      subMenu: [
        {
          path: "/complain/teacher",
          label: "Regarding Teacher",
          icon: <FiPackage size={20} />,
        },
        {
          path: "/complain/other",
          label: "Other Issues",
          icon: <FaUserCircle size={20} />,
        },
        {
          path: "/complain/all",
          label: "Complain Records",
          icon: <HiUsers size={20} />,
        },
      ],
    },
    userRole !== "parent" && {
      name: "Task & Announcement",
      icon: <MdAnnouncement size={22} />,
      subMenu: [
        {
          path: "/task/all",
          label: "Tasks",
          icon: <FiPackage size={20} />,
        },
        {
          path: "/announcement/all",
          label: "Announcements",
          icon: <FaUserCircle size={20} />,
        },
      ],
    },
    userRole === "parent" && {
      name: "Announcements",
      icon: <MdAnnouncement size={22} />,
      path: "/announcement/all",
    },
    {
      name: "Notifications",
      icon: <IoMdNotifications size={22} />,
      path: "/notifications",
    },
    userRole == "parent" && {
      name: "Transactions",
      icon: <SiMoneygram size={22} />,
      path: "/transactions",
    },
    {
      name: "Profile",
      icon: <IoPersonCircleSharp size={22} />,
      path: "/my-profile",
    },
  ].filter(Boolean);

  return SideBarMenu;
};

export { CreateSideBarMenu };
