import React, { useEffect, useState } from "react";
import NoData from "../../Component/NoData/NoData";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import StatsCard from "../../Component/StatsCard";
import TableSkeleton from "../../Component/TableSkeleton";
import { BaseURL, recordsLimit } from "../../config/apiUrl";
import classes from "./TeacherDashboard.module.css";
import { Col, Container, Row } from "react-bootstrap";
import { Button } from "../../Component/Button/Button";
import { useSelector } from "react-redux";
import { Get } from "../../Axios/AxiosFunctions";
import { Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { AiOutlineLike } from "react-icons/ai";
import { CgSmileNone } from "react-icons/cg";

import { MdCalendarToday } from "react-icons/md";
import { FiBook } from "react-icons/fi";
import { TotalClassesSvg } from "../../constant/imagePath";
import { ReactSVG } from "react-svg";
import TaskDetailModal from "../../modals/TaskDetailModal";
import AnnouncementDetailModal from "../../modals/AnnouncementDetailModal";

const TeacherDashboard = () => {
  const navigate = useNavigate();
  const { access_token } = useSelector((state) => state.authReducer);
  const [loading, setLoading] = useState(false);
  const [classesList, setClassesList] = useState([]);
  const [stats, setStats] = useState({});
  const [taskModalShow, setTaskModalShow] = useState(false);
  const [announcementModalShow, setAnnouncementModalShow] = useState(false);
  const [selectedTask, setSelectedTask] = useState({});
  const [selectedAnnouncement, setSelectedAnnouncement] = useState({});
  
  const [tasks, setTasks] = useState([]);
  const [upcomingAnnouncements, setUpcomingAnnouncements] = useState([]);

  const statsData = [
    {
      title: "Total Students",
      count: stats?.totalActiveStudents || 0,
      icon: (
        <ReactSVG
          src={TotalClassesSvg}
          beforeInjection={(svg) => svg.classList.add(classes.statsSvgIcon)}
        />
      ),
    },
    {
      title: "Upcoming Classes",
      count: stats?.upcomingClasses || 0,
      icon: <AiOutlineLike size={40} className={classes.statsIcon} />,
    },

    {
      title: "Absent Classes",
      count: stats?.absentClasses || 0,
      icon: <CgSmileNone size={40} className={classes.statsIcon} />,
    },
    {
      title: "Class Request",
      count: stats?.totalClassRequests || 0,
      path: "/class/request",
      icon: <MdCalendarToday size={38} className={classes.statsIcon} />,
    },
    {
      title: "Courses",
      count: stats?.courses || 0,
      path: "/course/all",
      icon: <FiBook size={40} className={classes.statsIcon} />,
    },
  ];


  // get
  const getData = async () => {
    const statsUrl = BaseURL(`teacher/dashboard-stats`);
    // const classesUrl = BaseURL(
    //   `class/todays-classes?status=today&page=1&limit=5`
    // );

    setLoading(true);
    const response = await Get(statsUrl,access_token);
    // const [statsResponse, classesResponse] = await Promise.allSettled([
    //   Get(statsUrl, access_token),
    //   Get(classesUrl, access_token),
    // ]);
    if (response) {
      setStats(response?.data?.data);
      setUpcomingAnnouncements(
        response?.data?.data?.upcomingAnnouncements
      );
      setTasks(response?.data?.data?.tasks);
    setLoading(false);
    setClassesList(response?.data?.todayClasses)

    }
    // if (classesResponse) {
    //   setClassesList(classesResponse?.value?.data?.data);
    // }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <style>{`
      .table100-body{
        // height:calc(100vh - 360px);
        overflow-y:scroll;
      }
  @media screen and (max-width:1440px){
      .table100-head, .table100-body{
        width:1200px;
      }
      .table100.ver1{
        overflow-x:scroll !important;
      }
    }

      `}</style>
      <div>
        <SideBarSkeleton heading={"Dashboard"}>
          <Container className={classes.main}>
            <Row className={classes.row}>
              <Col md={12}>
                <div className={classes.statsWrap}>
                  {loading
                    ? Array(5)
                        .fill(0)
                        .map((_, index) => (
                          <div key={index} className={classes.statsCol}>
                            <Skeleton
                              height={"140px"}
                              width={"100%"}
                              variant={"rounded"}
                            />
                          </div>
                        ))
                    : statsData?.map((item, index) => (
                        <div key={index} className={classes.statsCol}>
                          <StatsCard data={item} />
                        </div>
                      ))}
                </div>
              </Col>

              {/* Upcoming Announcements */}
              <Col md={6}>
                <div
                  className={[classes.contentWrapper, "scrollbar"].join(" ")}
                >
                  <div className={classes.head}>
                    <h6>Upcoming Announcements</h6>
                    {/* {upcomingAnnouncements.length > 0 && <Button onClick={() => navigate("/announcement/all")}>
                      See all
                    </Button>} */}
                  </div>
                  <div className={classes.contentDiv}>
                    {loading &&
                      Array(3)
                        .fill(0)
                        .map((_, index) => (
                          <div key={index} className={classes.skeleton}>
                            <Skeleton
                              height={"100px"}
                              width={"100%"}
                              variant={"rounded"}
                            />
                          </div>
                        ))
              }

                    {upcomingAnnouncements?.length > 0 ? (
                      upcomingAnnouncements?.map((item, key) => (
                        <div key={key} className={classes.announcementCard}
                        onClick={() => {
                          setAnnouncementModalShow(true);
                          setSelectedAnnouncement(item);
                        }}
                        >
                          <span className={"t-t-c"}>{item?.title}:</span>
                          <p className={"t-t-c"}>{item?.description}</p>
                          <div className={classes.dates}>
                            <div>
                              <label>Start Date:</label>
                              <span>{moment(item?.startDate).format("DD MMM YYYY")}</span>
                            </div>
                            <div>
                              <label>End Date:</label>
                              <span>{moment(item?.endDate).format("DD MMM YYYY")}</span>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      !loading &&<NoData text={"No Announcement Found"} />
                    )}
                  </div>
                </div>
              </Col>
              {/* Upcoming Announcements */}
              {/* Tasks */}
              <Col md={6}>
                <div
                  className={[classes.contentWrapper, "scrollbar"].join(" ")}
                >
                  <div className={classes.head}>
                    <h6>Tasks</h6>
                    {/* {tasks.length>0 && <Button onClick={() => navigate("/task/all")}>
                      See all
                    </Button>} */}
                  </div>
                  <div className={classes.contentDiv}>
                  {loading &&
                      Array(3)
                        .fill(0)
                        .map((_, index) => (
                          <div key={index} className={classes.skeleton}>
                            <Skeleton
                              height={"100px"}
                              width={"100%"}
                              variant={"rounded"}
                            />
                          </div>
                        ))}
                    {tasks?.length > 0 ? (
                      tasks?.map((item, index) => (
                        <div key={index} className={classes.taskCard}
                        onClick={() => {
                          setTaskModalShow(true);
                          setSelectedTask({ ...item, index });
                        }}
                        >
                        <span className={"t-t-c"}>{item?.title}:</span>
                        <p className={"t-t-c"}>{item?.description}</p>
                        <div className={classes.usersAndRoles}>
                          <p>Created At: {moment(item?.createdAt).format("DD MMM YYYY")}</p>
                          {/* <div>
                            {item?.role?.map((role, key) => (
                              <span key={key}>{role}</span>
                            ))}
                          </div> */}
                        </div>
                      </div>
                      ))
                    ) : (
                      !loading && <NoData text={"No Tasks Found"} />
                    )}
                  </div>
                </div>
              </Col>
              {/* Tasks */}

              <Col md={12}>
                <div className={classes.head}>
                  <h6>{"Today's Classes"}</h6>
                  {classesList?.length ==10 && <Button
                    label="See all"
                    onClick={() => navigate("/class/today")}
                  />}
                </div>
              </Col>
              <Col md={12}>
                <div className="table100 ver1 m-b-110">
                  <div className="table100-head">
                    <table>
                      <thead>
                        <tr className="row100 head">
                          <th
                            className="cell100 column1"
                            style={{ width: "12.5%", textAlign: "start" }}
                          >
                            S.No
                          </th>
                          <th
                            className="cell100 column2"
                            style={{ width: "12.5%", textAlign: "start" }}
                          >
                            Student Name
                          </th>
                          <th
                            className="cell100 column3"
                            style={{ width: "12.5%", textAlign: "start" }}
                          >
                            Date
                          </th>
                          <th
                            className="cell100 column4"
                            style={{ width: "12.5%", textAlign: "start" }}
                          >
                            Start Time
                          </th>
                          <th
                            className="cell100 column4"
                            style={{ width: "12.5%", textAlign: "start" }}
                          >
                            End Time
                          </th>
                          <th
                            className="cell100 column4"
                            style={{ width: "12.5%", textAlign: "start" }}
                          >
                            Course
                          </th>
                          <th
                            className="cell100 column4"
                            style={{ width: "12.5%", textAlign: "start" }}
                          >
                            Status
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                  {loading ? (
                    <TableSkeleton rowsCount={recordsLimit} colsCount={7} />
                  ) : (
                    <div className="table100-body js-pscroll ps ps--active-y">
                      <table>
                        <tbody>
                          {classesList?.length > 0 ? (
                            classesList?.map((item, index) => (
                              <tr className="row100 body" key={index}>
                                <td
                                  className="cell100 column1"
                                  style={{ width: "12.5%", textAlign: "left" }}
                                >
                                  {index + 1}
                                </td>
                                <td
                                  className="cell100 column2"
                                  style={{
                                    width: "12.5%",
                                    textAlign: "left",
                                  }}
                                >
                                  <div className={classes.descCol}>
                                    {item?.student?.firstName}{" "}
                                    {item?.student?.lastName}
                                  </div>
                                </td>

                                <td
                                  className="cell100 column4"
                                  style={{ width: "12.5%", textAlign: "left" }}
                                >
                                  <div className={classes.descCol}>
                                    {moment(item?.startTime, "HH:mm").format(
                                      "hh:mm A"
                                    )}
                                  </div>
                                </td>
                                <td
                                  className="cell100 column4"
                                  style={{ width: "12.5%", textAlign: "left" }}
                                >
                                  <div className={classes.descCol}>
                                    {item?.classType}
                                  </div>
                                </td>
                                <td
                                  className="cell100 column4"
                                  style={{ width: "12.5%", textAlign: "left" }}
                                >
                                  <div className={classes.descCol}>
                                    {item?.classType || "-"}
                                  </div>
                                </td>
                                <td
                                  className="cell100 column4"
                                  style={{ width: "12.5%", textAlign: "left" }}
                                >
                                  <div className={classes.descCol}>
                                    {item?.classType || "-"}
                                  </div>
                                </td>
                                <td
                                  className="cell100 column4"
                                  style={{ width: "12.5%", textAlign: "left" }}
                                >
                                  <div className={classes.descCol}>
                                    {item?.classType || "-"}
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <NoData text={"No Class Found"} />
                          )}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            {taskModalShow && (
              <TaskDetailModal
                show={taskModalShow}
                setShow={setTaskModalShow}
                selected={selectedTask}
                data={tasks}
                setData={setTasks}
              />
            )}
            {announcementModalShow && (
              <AnnouncementDetailModal
                show={announcementModalShow}
                setShow={setAnnouncementModalShow}
                data={selectedAnnouncement}
              />
            )}
          </Container>
        </SideBarSkeleton>
      </div>
    </>
  );
};
export default TeacherDashboard;
