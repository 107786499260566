import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Button } from "../../Component/Button/Button";
import ModalSkeleton from "../ModalSkeleton";
import styles from "./MarkAsCompletedModal.module.css";
import { Checkbox } from "../../Component/Checkbox/Checkbox";

const MarkAsCompletedModal = ({ show, setShow, onSubmit }) => {
  const [isAttended, setIsAttended] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleUpdate = async () => {
    setIsLoading(true);
    await onSubmit(isAttended);
    setIsLoading(false);
  };
  return (
    <ModalSkeleton
      setShow={setShow}
      show={show}
      width={"600px"}
      header={"Mark As Complete"}
      borderRadius={"10px"}
      showCloseIcon
    >
      <Row className={styles.row}>
        <Col md={12}>
          <Checkbox
            value={isAttended}
            setValue={setIsAttended}
            label={"Mark student as present"}
          />
        </Col>

        <Col md={12} className={styles.signUpBtnWrap}>
          <Button
            label={isLoading ? "Submitting..." : "Submit"}
            className={styles.UpdateBtn}
            variant={""}
            onClick={handleUpdate}
          />
        </Col>
      </Row>
    </ModalSkeleton>
  );
};

export default MarkAsCompletedModal;
