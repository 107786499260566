/* eslint-disable */

import React, { useRef } from "react";
import { RiDeleteBinLine } from "react-icons/ri";
import classes from "./UploadImageBox.module.css";
import { Button } from "../Button/Button";
import { toast } from "react-toastify";
import { CgImage } from "react-icons/cg";
import { UploadImageValidtor } from "../../constant/commonFunctions";
import { imageUrl } from "../../config/apiUrl";

function UploadImageBox({ state, setter, edit = true, isMultiple = true }) {
  const inputRef = useRef(null);
  const HandleUploadFile = (e) => {
    if (isMultiple) {
      let notImage = false;
      Array.from(e.target.files)?.map((file) => {
        if (!UploadImageValidtor(file)) {
          return (notImage = true);
        }
      });
      if (notImage) {
        return toast.error("Invalid Image");
      }
      setter((pre) => [...pre, ...Array.from(e.target.files)]);
    } else {
      let file = e.target.files[0];
      if (!UploadImageValidtor(file)) {
        return toast.error("Invalid Image");
      }
      setter(file);
    }
  };
  const HandleDeleteFile = (index) => {
    if (isMultiple) {
      setter((prev) => prev.filter((_, i) => i !== index));
    } else {
      setter(null);
    }
  };

  return (
    <div className={classes.box}>
      {(isMultiple || state == null) && (
        <div
          className={classes.uploadBox}
          onClick={() => inputRef.current.click()}
        >
          <CgImage className={classes.uploadIcon} />
        </div>
      )}
      {Array.isArray(state)
        ? state.map((file, index) => (
            <div className={classes.uploadBox}>
              <img
                src={
                  typeof file == "object"
                    ? URL.createObjectURL(file)
                    : imageUrl(file)
                }
              />
              {edit && (
                <div className={classes.editAndDelete}>
                  <Button
                    className={classes.icon}
                    onClick={() => HandleDeleteFile(index)}
                    leftIcon={<RiDeleteBinLine />}
                  />
                </div>
              )}
            </div>
          ))
        : state && (
            <div className={classes.uploadBox}>
              <img
                src={
                  typeof file == "object"
                    ? URL.createObjectURL(file)
                    : imageUrl(file)
                }
              />
              {edit && (
                <div className={classes.editAndDelete}>
                  <Button
                    className={classes.icon}
                    onClick={() => HandleDeleteFile(index)}
                    leftIcon={<RiDeleteBinLine />}
                  />
                </div>
              )}
            </div>
          )}

      {/* Input For File Upload */}
      <input
        hidden
        type="file"
        ref={inputRef}
        onChange={HandleUploadFile}
        multiple={isMultiple}
        accept="image/*"
      />
    </div>
  );
}

export default UploadImageBox;
