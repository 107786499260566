import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { Patch } from "../../Axios/AxiosFunctions";
import { Button } from "../../Component/Button/Button";
import { Input } from "../../Component/Input/Input";
import { apiHeader, BaseURL } from "../../config/apiUrl";
import ModalSkeleton from "../ModalSkeleton";
import { useSelector, useDispatch } from "react-redux";
import styles from "./UpdatePassword.module.css";
import { saveLoginUserData } from "../../store/auth/authSlice";

const UpdatePasswordModal = ({ show, setShow }) => {
  const dispatch = useDispatch();

  const inputStyle = {};
  const { access_token } = useSelector((state) => state.authReducer);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // handle update
  const handleUpdate = async () => {
    const params = {
      currentPassword: currentPassword,
      newPassword: newPassword,
      confirmNewPassword: confirmPassword,
    };
    // validate
    for (let key in params)
      if (params[key] === "") return toast.error(`All fields are required.`);

    // validate password
    if (newPassword.length < 8)
      return toast.warn("Password must be at least 8 characters long.");
    if (newPassword !== confirmPassword)
      return toast.warn("Password and confirm password do not match.");
    if (newPassword === currentPassword)
      return toast.warn(
        "New password and current password should not be same."
      );

    // call api
    const url = BaseURL(`users/updateMyPassword`);
    setIsLoading(true);
    const response = await Patch(url, params, apiHeader(access_token));
    setIsLoading(false);

    if (response != undefined) {
      dispatch(saveLoginUserData(response?.data));
      toast.success("Your password has been updated successfully.");
      setShow(false);
    }
  };

  return (
    <ModalSkeleton
      setShow={setShow}
      show={show}
      width={"600px"}
      header={"Update Password"}
      borderRadius={"20px"}
      showCloseIcon
    >
      <Row className={styles.row}>
        <Col md={12}>
          <Input
            setter={setCurrentPassword}
            value={currentPassword}
            placeholder={"Enter Current Password"}
            label={"Current Password"}
            type={"password"}
            customStyle={inputStyle}
          />
        </Col>
        <Col md={12}>
          <Input
            setter={setNewPassword}
            value={newPassword}
            placeholder={"Enter New password"}
            label={"New Password"}
            type={"password"}
            customStyle={inputStyle}
          />
        </Col>
        <Col md={12}>
          <Input
            setter={setConfirmPassword}
            value={confirmPassword}
            placeholder={"Enter Confirm password"}
            label={"Confirm Password"}
            type={"password"}
            customStyle={inputStyle}
          />
        </Col>

        <Col md={12} className={styles.signUpBtnWrap}>
          <Button
            label={isLoading ? "Upadating" : "Update Password"}
            className={styles.UpdateBtn}
            variant={""}
            onClick={handleUpdate}
            disabled={isLoading}
          />
        </Col>
      </Row>
    </ModalSkeleton>
  );
};

export default UpdatePasswordModal;
