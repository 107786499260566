/* eslint-disable */

import React, { useEffect, useState } from "react";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import { Col, Container, Row } from "react-bootstrap";
import classes from "./ClassRequestDetail.module.css";
import { useParams } from "react-router-dom";
import { Get, Post } from "../../Axios/AxiosFunctions";
import { useSelector } from "react-redux";
import LottieLoader from "../../Component/LottieLoader/LottieLoader";
import { BaseURL, apiHeader, imageUrl } from "../../config/apiUrl";
import moment from "moment";
import { Button } from "../../Component/Button/Button";
import NoData from "../../Component/NoData/NoData";

const ClassRequestDetail = () => {
  const classRequestId = useParams().id;
  const user = useSelector((state) => state.authReducer.user);
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const [isLoading, setIsLoading] = useState(false);
  const [classRequestData, setClassRequestData] = useState(null);
  const url = BaseURL(user?.role);

  // getClassRequestDetail
  const getClassRequestDetail = async () => {
    const apiUrl = `${url}/class-request/${classRequestId}`;
    setIsLoading(true);
    const response = await Get(apiUrl, accessToken);
    setIsLoading(false);

    if (response) {
      setClassRequestData(response?.data?.data);
    }
  };

  useEffect(() => {
    getClassRequestDetail();
  }, []);

  const HandleSubmit = async () => {
    const apiUrl = `${url}/acceptClasses`;
    const body = {
      classRequestId,
    };
    setIsLoading(true);
    const response = await Post(apiUrl, body, apiHeader(accessToken));
    if (response) {
      setClassRequestData(response?.data?.data?.classRequest);
    }
    setIsLoading(false);
  };

  return (
    <>
      <style>{`
      .table100-body{
        overflow-y:scroll;
      }
  @media screen and (max-width:1440px){
      .table100-head, .table100-body{
        width:1200px;
      }
      .table100.ver1{
        overflow-x:scroll !important;
      }
    }

      `}</style>
      <SideBarSkeleton heading={"Request Detail"}>
        <div className={classes?.topContainer}>
          <Container className={classes.main}>
            <Row className="g-0">
              <Col md={12}>
                <h5 className={classes?.mainHeading}>Student Detail</h5>
              </Col>
              <Col md={12} className={classes.detailCard}>
                <div className={classes.profileImageContainer}>
                  <img
                    src={imageUrl(classRequestData?.student?.photo)}
                    alt="student"
                  />
                </div>
                <Row>
                  <Col md={4}>
                    <div className={`${classes?.singleItem} text-start`}>
                      <b>Name:</b>
                      <p className="t-t-c">
                        {classRequestData?.student?.firstName}{" "}
                        {classRequestData?.student?.lastName}
                      </p>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className={`${classes?.singleItem} text-start`}>
                      <b>Email:</b>
                      <p>{classRequestData?.student?.email}</p>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className={`${classes?.singleItem} text-start`}>
                      <b>Phone:</b>
                      <p>{classRequestData?.student?.phone}</p>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className={`${classes?.singleItem} text-start`}>
                      <b>Alt Phone:</b>
                      <p>{classRequestData?.student?.altPhone}</p>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className={`${classes?.singleItem} text-start`}>
                      <b>Nationality:</b>
                      <p>{classRequestData?.student?.nationality}</p>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className={`${classes?.singleItem} text-start`}>
                      <b>Gender:</b>
                      <p>{classRequestData?.student?.gender}</p>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className={`${classes?.singleItem} text-start`}>
                      <b>Age:</b>
                      <p>{classRequestData?.student?.age}</p>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className={`${classes?.singleItem} text-start`}>
                      <b>Course:</b>
                      <p className="t-t-c">{classRequestData?.subscription?.course?.title}</p>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className={`${classes?.singleItem} text-start`}>
                      <b>Package:</b>
                      <p>
                        {classRequestData?.subscription?.package?.packageType}{" "}
                        minutus,{" "}
                        {(classRequestData?.subscription?.package
                          ?.numberOfClasses *
                          1) /
                          4}{" "}
                        class per week
                      </p>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className={`${classes?.singleItem} text-start`}>
                      <b>Request Class type:</b>
                      <p>{classRequestData?.classType}</p>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className={`${classes?.singleItem} text-start`}>
                      <b>No Of Classes In Request:</b>
                      <p>{classRequestData?.subscription?.classes?.length}</p>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className={`${classes?.singleItem} text-start`}>
                      <b>Request Status:</b>
                      <p>{classRequestData?.status}</p>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className={`${classes?.singleItem} text-start`}>
                      <b>Student Address:</b>
                      <p>{classRequestData?.student?.address || "N/A"}</p>
                    </div>
                  </Col>
                  {classRequestData?.status == "pending" &&
                    user?.role == "teacher" && (
                      <Col md={12}>
                        <div title="Accept Class Request">
                          <Button
                            className={classes.acceptBtn}
                            label={"Accept"}
                            onClick={HandleSubmit}
                          />
                        </div>
                      </Col>
                    )}
                </Row>
              </Col>
              <Col md={12}>
                <h5 className={classes?.mainHeading}>Class Schedule</h5>
              </Col>
              <Col md={12} className={classes.tableMain}>
                <div className="table100 ver1 m-b-110">
                  <div className="table100-head">
                    <table>
                      <thead>
                        <tr className="row100 head">
                          <th
                            className="cell100 column1"
                            style={{ width: "10%", textAlign: "left" }}
                          >
                            S.No
                          </th>
                          <th
                            className="cell100 column2"
                            style={{ width: "20%", textAlign: "left" }}
                          >
                            Date
                          </th>
                          <th
                            className="cell100 column3"
                            style={{ width: "15%", textAlign: "left" }}
                          >
                            Start Time
                          </th>
                          <th
                            className="cell100 column4"
                            style={{ width: "15%", textAlign: "left" }}
                          >
                            End Time
                          </th>
                          <th
                            className="cell100 column5"
                            style={{ width: "20%", textAlign: "left" }}
                          >
                            Class Type
                          </th>
                          <th
                            className="cell100 column6"
                            style={{ width: "20%", textAlign: "left" }}
                          >
                            Status
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                  <div className={`table100-body js-pscroll ps ps--active-y`}>
                    <table>
                      <tbody>
                        {classRequestData?.subscription?.classes?.length > 0
                          ? classRequestData?.subscription?.classes?.map(
                              (item, index) => (
                                <tr key={index} className="row100 body">
                                  <td
                                    className="cell100 column1"
                                    style={{ width: "10%", textAlign: "left" }}
                                  >
                                    {index + 1}
                                  </td>
                                  <td
                                    className="cell100 column2"
                                    style={{ width: "20%", textAlign: "left" }}
                                  >
                                    <div className={`${classes.descCol} t-t-c`}>
                                      {moment(item?.date).format("DD-MM-YYYY")}
                                    </div>
                                  </td>
                                  <td
                                    className="cell100 column3"
                                    style={{ width: "15%", textAlign: "left" }}
                                  >
                                    <div className={classes.descCol}>
                                      {moment(item?.startTime, "HH:mm").format(
                                        "HH:mm A"
                                      )}
                                    </div>
                                  </td>

                                  <td
                                    className="cell100 column4"
                                    style={{ width: "15%", textAlign: "left" }}
                                  >
                                    <div className={classes.descCol}>
                                      {moment(item?.endTime, "HH:mm").format(
                                        "HH:mm A"
                                      )}
                                    </div>
                                  </td>
                                  <td
                                    className="cell100 column5"
                                    style={{ width: "20%", textAlign: "left" }}
                                  >
                                    <div className={classes.descCol}>
                                      {item?.classType}
                                    </div>
                                  </td>
                                  <td
                                    className="cell100 column5"
                                    style={{ width: "20%", textAlign: "left" }}
                                  >
                                    <div className={classes.descCol}>
                                      {item?.status}
                                    </div>
                                  </td>
                                </tr>
                              )
                            )
                          : !isLoading && <NoData text={"No Classes Found"} />}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </SideBarSkeleton>

      {isLoading && <LottieLoader />}
    </>
  );
};

export default ClassRequestDetail;
