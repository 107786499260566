/* eslint-disable */

import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Get, Patch } from "../../Axios/AxiosFunctions";
import { Button } from "../../Component/Button/Button";
import { DropDown } from "../../Component/DropDown/DropDown";
import NoData from "../../Component/NoData/NoData";
import PaginationComponent from "../../Component/PaginationComponent";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import TableSkeleton from "../../Component/TableSkeleton";
import { BaseURL, recordsLimit } from "../../config/apiUrl";
import classes from "./TaskPage.module.css";
import { registeredUsers } from "../../config/DummyData";
import { useNavigate } from "react-router-dom";
import ShowMoreShowLessText from "../../Component/ShowMoreShowLess/ShowMoreShowLessText";
import TaskDetailModal from "../../modals/TaskDetailModal";
const statusOptions = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Pending",
    value: "pending",
  },
  {
    label: "Completed",
    value: "completed",
  },
];

const TaskPage = () => {
  const navigate = useNavigate();
  const { access_token } = useSelector((state) => state?.authReducer);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState(statusOptions[0]);

  const [show, setShow] = useState(false);
  const [page, setPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const DummyData = [
    {
      name: "John Doe",
      email: "johnemail@gmail.com",
      status: "active",
      title: "Class 1",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla eget aliquam quam.",
      date: "12-12-2021",
    },
    {
      name: "John Doe",
      email: "johnemail@gmail.com",
      status: "active",
      title: "Class 1",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla eget aliquam quam.",
      date: "12-12-2021",
    },
    {
      name: "John Doe",
      email: "johnemail@gmail.com",
      status: "active",
      title: "Class 1",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla eget aliquam quam.",
      date: "12-12-2021",
    },
  ];

  // getData
  async function getData(pg = page, sts = status?.value) {
    const url = BaseURL(
      `task/my-tasks?status=${sts}&page=${pg}&limit=${recordsLimit}`
    );
    setLoading(true);
    const response = await Get(url, access_token);
    if (response) {
      setData(response?.data?.data);
      setTotalResults(response?.data?.totalCount);
      setLoading(false);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <style>{`
      .table100-body{
        height:calc(100vh - 370px);
        overflow-y:scroll;
      }
  @media screen and (max-width:1440px){
      .table100-head, .table100-body{
        width:1200px;
      }
      .table100.ver1{
        overflow-x:scroll !important;
      }
    }

      `}</style>
      <div>
        <SideBarSkeleton heading={"All Tasks"}>
          <Container className={classes.main}>
            {/* table */}
            <Row className={classes.row}>
              <Col md={12}>
                <div className={classes.alignRight}>
                  <DropDown
                    options={statusOptions}
                    setter={(e) => {
                      setStatus(e);
                      setPage(1, e?.value);
                      getData(1, e?.value);
                    }}
                    customStyle={{ minWidth: "180px" }}
                    value={status}
                  />
                </div>
              </Col>
              <Col md={12} className={classes.tableMain}>
                <div className="table100 ver1 m-b-110">
                  <div className="table100-head">
                    <table>
                      <thead>
                        <tr className="row100 head">
                          <th
                            className="cell100 column1"
                            style={{ width: "16.5%", textAlign: "start" }}
                          >
                            S.No
                          </th>

                          <th
                            className="cell100 column3"
                            style={{ width: "16.6%", textAlign: "start" }}
                          >
                            Title
                          </th>
                          <th
                            className="cell100 column4"
                            style={{ width: "16.5%", textAlign: "start" }}
                          >
                            Description
                          </th>
                          <th
                            className="cell100 column2"
                            style={{ width: "16.5%", textAlign: "start" }}
                          >
                            CreatedAt
                          </th>
                          <th
                            className="cell100 column2"
                            style={{ width: "16.5%", textAlign: "start" }}
                          >
                            Status
                          </th>

                          <th
                            className="cell100 column5"
                            style={{ width: "16.5%" }}
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                  {loading ? (
                    <TableSkeleton rowsCount={recordsLimit} colsCount={7} />
                  ) : (
                    <div className="table100-body js-pscroll ps ps--active-y">
                      <table>
                        <tbody>
                          {data?.length > 0 ? (
                            data?.map((item, index) => (
                              <tr className="row100 body" key={index}>
                                <td
                                  className="cell100 column1"
                                  style={{ width: "16.5%", textAlign: "left" }}
                                >
                                  {index + 1}
                                </td>
                                <td
                                  className="cell100 column2"
                                  style={{
                                    width: "16.6%",
                                    textAlign: "left",
                                  }}
                                >
                                  <div className={classes.descCol}>
                                    {item?.title}
                                  </div>
                                </td>

                                <td
                                  className="cell100 column4"
                                  style={{ width: "16.5%", textAlign: "left" }}
                                >
                                  <div className={classes.descCol}>
                                    {
                                      <ShowMoreShowLessText
                                        text={item?.description}
                                        visibility={50}
                                      />
                                    }
                                  </div>
                                </td>
                                <td
                                  className="cell100 column4"
                                  style={{ width: "16.6%", textAlign: "left" }}
                                >
                                  <div className={classes.descCol}>
                                    {moment(item?.createdAt).format(
                                      "DD MMM YY"
                                    )}
                                  </div>
                                </td>
                                <td
                                  className="cell100 column2"
                                  style={{
                                    width: "16.5%",
                                    textAlign: "left",
                                  }}
                                >
                                  {item?.users?.status}
                                </td>

                                <td
                                  className="cell100 column5"
                                  style={{ width: "16.5%" }}
                                >
                                  <div className={classes.actions_btn}>
                                    <Button
                                      className={classes.viewDetailBtn}
                                      label={"Details"}
                                      onClick={() => {
                                        setShow(true);
                                        setSelectedRow({ ...item, index });
                                      }}
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <NoData text={"No Tasks Found"} />
                          )}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </Col>
              <Col md={12} className={classes.paginationDiv}>
                {!!data?.length && (
                  <PaginationComponent
                    totalPages={Math.ceil(totalResults / recordsLimit)}
                    setCurrentPage={(e) => {
                      setPage(e);
                      getData(e, status?.value);
                    }}
                    currentPage={page}
                  />
                )}
              </Col>
            </Row>
          </Container>
        </SideBarSkeleton>
      </div>
      {show && <TaskDetailModal
        show={show}
        setShow={setShow}
        selected={selectedRow}
        currentStatus={status?.value}
        data={data}
        setData={setData}
      />}
    </>
  );
};
export default TaskPage;
