import React, { useEffect, useState } from "react";
import Style from "./AfterLoginHeader.module.css";
import { Container, OverlayTrigger } from "react-bootstrap";
import { MdOutlineNotifications } from "react-icons/md";
import HeaderNotification from "../HeaderNotification/HeaderNotification";
import { useDispatch, useSelector } from "react-redux";
import { imageUrl } from "../../config/apiUrl";
// import { ProfileImg } from "../../constant/imagePath";
import profileImg from "../../assets/images/brandCard2.png";
import { clearNotiData } from "../../store/common/commonSlice";
import { isMobileViewHook } from "../../CustomHooks/isMobileViewHook";
import { useLocation } from "react-router-dom";

export const AfterLoginHeader = ({ className, heading }) => {
  const dispatch = useDispatch();
  const [showNotificationOverlay, setShowNotificationOverlay] = useState(false);
  const { user } = useSelector((state) => state?.authReducer);
  const { noti_counter } = useSelector((state) => state?.commonReducer);
  const [isMobile, setIsMobile] = useState(false);

  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const paramsFound = searchParams?.get("roomData");

  useEffect(() => {
    isMobileViewHook(setIsMobile, 575);
  }, []);
  useEffect(() => {
    if (!isMobile) {
      setShowNotificationOverlay(false);
    }
  }, [isMobile]);

  return (
    <Container
      style={{
        display: paramsFound && "none",
      }}
      className={`${[Style.navbarContainer, className].join(" ")}`}
    >
      <div className={Style.leftSide}>
        <span className={Style?.pageHeading}>{heading}</span>
      </div>
      <div className={Style.rightSide}>
      {!isMobile && (
        <div className={[Style.iconDiv].join(" ")}>
          
            <OverlayTrigger
              placement={"bottom"}
              show={showNotificationOverlay}
              trigger={["click"]}
              rootClose={true}
              overlay={
                <div className={[Style.notifyoverlayDiv]}>
                  <HeaderNotification />
                </div>
              }
              onToggle={() => {
                setShowNotificationOverlay(!showNotificationOverlay);
                dispatch(clearNotiData());
              }}
            >
              <div className={Style.notiIcon}>
                <MdOutlineNotifications
                  color={"var(--white-color)"}
                  size="20px"
                />
                {noti_counter > 0 && (
                  <div className={Style.notiCount}>{noti_counter}</div>
                )}
              </div>
            </OverlayTrigger>
        </div>
          )}
        <div className={Style.userWrap}>
          <p>{[user?.firstName,user?.lastName].join(" ")}</p>
          <div className={[Style.profileImg]}>
            <img
              src={`${imageUrl(user?.photo)}`}
              alt="..."
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = profileImg;
              }}
            />
          </div>
        </div>
      </div>
    </Container>
  );
};
