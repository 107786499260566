/* eslint-disable */
import React, { useState } from "react";
import ModalSkeleton from "../ModalSkeleton";
import classes from "./TaskDetailModal.module.css";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap/esm";
import { Button } from "../../Component/Button/Button";
import ShowMoreShowLessText from "../../Component/ShowMoreShowLess/ShowMoreShowLessText";
import UploadMultiplePDF from "../../Component/UploadMultiplePDF/UploadMultiplePDF";
import {
  BaseURL,
  CreateFormData,
  apiHeader,
  pdfUrl,
} from "../../config/apiUrl";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Post } from "../../Axios/AxiosFunctions";

const TaskDetailModal = ({
  show,
  setShow,
  selected,
  currentStatus,
  data,
  setData,
}) => {
  const { access_token, user } = useSelector((state) => state?.authReducer);
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const onComplete = async () => {
    const url = `task/submit-task/${selected?.slug}`;
    const params = {
      pdf: file,
    };
    if (!file) {
      return toast.warn("Kindly Selected Pdf File");
    }
    const formData = CreateFormData(params);
    setIsLoading(true);
    const response = await Post(
      BaseURL(url),
      formData,
      apiHeader(access_token)
    );
    if (response) {
      const tempArray = [...data];
      if (currentStatus === "all") {
        const updatedItem = response.data?.data?.users?.find((item) => {
          return item?.userId?._id == user?._id;
        });
        tempArray[selected?.index].users = updatedItem;
      } else {
        tempArray?.splice(selected?.index, 1);
      }
      setData(tempArray);
      setIsLoading(false);
      setShow(false);
      return toast.success("Task Successfully Submitted");
    }
  };
  return (
    <div>
      <ModalSkeleton
        setShow={setShow}
        show={show}
        width={"600px"}
        header={"Task Detail"}
        borderRadius={"20px"}
        modalClass={classes.modalClass}
          showCloseIcon
      >
        <div className={classes.main}>
          <div className={classes.body}>
            <Row className={classes.row}>
              <Col md={6}>
                <p>
                  <b>Title: </b>
                  {selected?.title}
                </p>
              </Col>

              <Col md={6}>
                <p>
                  <b>Status: </b>
                  {selected?.users?.status}
                </p>
              </Col>
              <Col md={12}>
                <b>Description: </b>
                <p>
                  <ShowMoreShowLessText
                    text={selected?.description}
                    visibility={400}
                  />
                </p>
              </Col>
              {
                selected?.users?.status === "pending" && (
                  <Col md={12}>
                    <UploadMultiplePDF
                      isMultiple={false}
                      state={file}
                      setter={setFile}
                    />
                  </Col>
                )
                // : (
                //   <Col md={12}>
                //     <div className={classes?.pdfContainer}>
                //       <iframe
                //         src={pdfUrl(
                //           `${selected?.users?.document}${"#toolbar=0"}`
                //         )}
                //         type="application/pdf"
                //         width="100%"
                //         height="100%"
                //         id="pdfFrame"
                //         seamless="seamless"
                //       ></iframe>
                //     </div>
                //   </Col>
                // )
              }
              {selected?.users?.status == "pending" ? (
                <Col md={12} className={classes.btnCenter}>
                  <Button
                    className={classes.btn}
                    onClick={
                      selected?.users?.status === "pending" ? onComplete : null
                    }
                    disabled={!file || isLoading}
                  >
                    {isLoading ? "Wait..." : "Complete"}
                  </Button>
                </Col>
              ) : (
                <Col md={12} className={classes.btnCenter}>
                  <a
                    target="_blank"
                    href={`${pdfUrl(selected?.users?.document)}`}
                  >
                    <Button className={classes.btn}>View Task</Button>
                  </a>
                </Col>
              )}
            </Row>
          </div>
        </div>
      </ModalSkeleton>
    </div>
  );
};

export default TaskDetailModal;
