import React, { useState } from "react";
import classes from "./SideBar.module.css";
import { TbLogout } from "react-icons/tb";
import { useLocation, useNavigate } from "react-router-dom";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiUrl } from "../../config/apiUrl";
import { io } from "socket.io-client";
import { signOutRequest } from "../../store/auth/authSlice";
import { RiArrowDownSFill, RiArrowUpSFill } from "react-icons/ri";
import { CreateSideBarMenu } from "../../Helper/CommonFunctions";

const SideBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, fcmToken } = useSelector((state) => state?.authReducer);

  const SideBarMenu = CreateSideBarMenu(user?.role);

  const RenderItem = ({ icon, title, path, subMenu = [] }) => {
    const navigate = useNavigate();
    const active = useLocation()?.pathname;
    const [subnav, setSubnav] = useState(false);
    const subActive = subMenu.find((item) => item?.path == active);
    const showSubnav = () => setSubnav(!subnav);
    return (
      <>
        <div
          className={[
            classes?.listItemContainer,
            path == active && classes?.active,
            subActive && classes?.subActive,
            subnav && classes?.marginZero,
          ].join(" ")}
          onClick={() => {
            if (subMenu?.length > 0) {
              showSubnav(!subnav);
            } else {
              navigate(path);
            }
          }}
        >
          {icon}
          <span>{title}</span>
          {subMenu?.length > 0 &&
            (subnav ? (
              <RiArrowUpSFill
                size={20}
                color={"white"}
                className={classes?.dropDownIcon}
              />
            ) : (
              <RiArrowDownSFill
                size={20}
                color={"white"}
                className={classes?.dropDownIcon}
              />
            ))}
        </div>
        {subnav &&
          subMenu.map((item, index) => {
            return (
              <div
                className={[
                  classes?.innerItemContainer,
                  item?.path == active && classes?.active,
                ].join(" ")}
                key={index}
                onClick={() => {
                  navigate(item?.path);
                }}
              >
                {item?.icon}
                <span>{item.label}</span>
              </div>
            );
          })}
      </>
    );
  };

  const socket = useRef(null);

  const logout = () => {
    socket.current = io(apiUrl);
    socket.current.emit("logout", { _id: user?._id, fcmToken });
    dispatch(signOutRequest());
    navigate("/");
  };

  return (
    <div className={classes?.mainContainer}>
      <div className={classes?.logoContainer}>
        <h4
          style={{
            color: "white",
          }}
        >
          Demo Project
        </h4>
        {/* <img src={whiteLogo} onClick={() => navigate("/")} alt="..." /> */}
      </div>
      {SideBarMenu?.map((item, index) => {
        return (
          <RenderItem
            key={index}
            icon={item?.icon}
            title={item?.name}
            path={item?.path}
            subMenu={item?.subMenu}
          />
        );
      })}
      <div className={[classes?.listItemContainer].join(" ")} onClick={logout}>
        <TbLogout size={22} />
        <span>Logout</span>
      </div>
    </div>
  );
};

export default SideBar;
