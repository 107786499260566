/* eslint-disable */

import { FaHome, FaUserCircle } from "react-icons/fa";
// get the role from the redux store
import store from "../store";
import { FiPackage } from "react-icons/fi";
import { HiUsers } from "react-icons/hi";
const userRole = "teacher";

const SideBarMenu = [
  {
    name: "Dashboard",
    icon: <FaHome size={22} />,
    path:
      userRole == "teacher"
        ? "/teacher/dashboard"
        : userRole == "parent"
        ? "/parent/dashboard"
        : "/student/dashboard",
  },
  // ...(userRole == "parent" && {
  //   name: "Students",
  //   icon: <FaHome size={22} />,
  //   path: "/student/all",
  // }),
  // ...(userRole == "teacher" && {
  //   name: "Class Requests",
  //   icon: <FaHome size={22} />,
  //   path: "/class/request",
  // }),
  {
    name: "Today's Classes",
    icon: <FaHome size={22} />,
    path: "/class/today",
  },
  {
    name: "Classes",
    icon: <FaHome size={22} />,
    path: "/class/all",
  },
  {
    name: "All Courses",
    icon: <FaHome size={22} />,
    path: "/course/all",
  },
  // ...(userRole == "teacher" && {
  //   name: "Salary",
  //   icon: <FaHome size={22} />,
  //   path: "/salary",
  // }),
  // ...(userRole !== "teacher" && {
  //   name: "Complain",
  //   icon: <FaHome size={22} />,
  //   subMenu: [
  //     {
  //       path: "/complain/teacher",
  //       label: "Regarding Teacher",
  //       icon: <FiPackage size={20} />,
  //     },
  //     {
  //       path: "/complain/other",
  //       label: "Other Issues",
  //       icon: <FaUserCircle size={20} />,
  //     },
  //     {
  //       path: "/complain/all",
  //       label: "Complain Records",
  //       icon: <HiUsers size={20} />,
  //     },
  //   ],
  // }),
  {
    name: "Task & Announcement",
    icon: <FaHome size={22} />,
    subMenu: [
      {
        path: "/task/all",
        label: "Tasks",
        icon: <FiPackage size={20} />,
      },
      {
        path: "/announcement/all",
        label: "Announcements",
        icon: <FaUserCircle size={20} />,
      },
    ],
  },
  {
    name: "Notifications",
    icon: <FaHome size={22} />,
    path: "/notifications",
  },
  // ...(userRole == "parent" && {
  //   name: "Transactions",
  //   icon: <FaHome size={22} />,
  //   path: "/transactions",
  // }),
  {
    name: "Profile",
    icon: <FaHome size={22} />,
    path: "/my-profile",
  },
];

const roles = ["parent", "teacher", "student"];

const registeredUsers = Array(10)
  .fill()
  .map(() => ({
    _id: 1,
    // Common
    email: "teacher@gmail.com",
    firstName: "Teacher",
    lastName: roles[Math.floor(Math.random() * roles.length)],
    photo:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTQEZrATmgHOi5ls0YCCQBTkocia_atSw0X-Q&usqp=CAU",
    phone: "08012345678",
    altPhone: "08012345678",
    timezone: "Africa/Lagos",
    address: "No 1, Teacher Street, Teacher Town Teacher State Teacher Country",
    nationality: "Nigerian",
    gender: "male",
    age: "30",
    currency: "USD",
    role: roles[Math.floor(Math.random() * roles.length)],
    isActive: true,
    isBlockedByAdmin: false,
    createdAt: Date.now(),

    // admin  + teacher
    CNIC: "https://i.pinimg.com/originals/be/84/ae/be84aeba62f8faf4a04bd2d6967c6360.png",
    materialStatus: true,

    // parent
    country: "Nigeria",
    city: "Lagos",

    // teacher
    qualification: "Bachelors",
    experience: "5 years",
    courses: Array(5).fill({
      _id: "1",
      title: "Mathematics",
      category: "regular", // additional, other
    }),
    schedule: [],
    amountPerHours: 10,
    reviews: [],
  }));

const statsData = [
  {
    _id: 1,
    name: "Total Students",
    value: 100,
  },
  {
    _id: 2,
    name: "Upcoming Classes",
    value: 100,
  },
  {
    _id: 3,
    name: "New Notifications",
    value: 100,
  },
];

const listOfTodayClasses = Array(10).fill({
  _id: Math.random() * 100000000000000000 * Math.random(),
  classTime: Date.now(),
  teacher: {
    firstName: "John",
    lastName: "Doe",
  },
  student: {
    firstName: "John",
    lastName: "Doe",
  },
  course: {
    title: "Maths",
    category: "regular",
  },
});

const packagesData = [
  ...Array(5).fill({
    type: 30,
    distribution: "1 class per week",
    priceInDollar: 100,
    priceInEuro: 100,
    descriptions: [
      "Lorem ipsum dolor sit amet, consectetur.",
      "Lorem ipsum dolor sit amet, consectetur.",
      "Lorem ipsum dolor sit amet, consectetur.",
      "Lorem ipsum dolor sit amet, consectetur.",
      "Lorem ipsum dolor sit amet, consectetur.",
      "Lorem ipsum dolor sit amet, consectetur.",
    ],
  }),
  ...Array(5).fill({
    type: 60,
    distribution: "1 class per week",
    priceInDollar: 200,
    priceInEuro: 100,
    descriptions: [
      "Lorem ipsum dolor sit amet, consectetur.",
      "Lorem ipsum dolor sit amet, consectetur.",
      "Lorem ipsum dolor sit amet, consectetur.",
      "Lorem ipsum dolor sit amet, consectetur.",
      "Lorem ipsum dolor sit amet, consectetur.",
      "Lorem ipsum dolor sit amet, consectetur.",
    ],
  }),
];

const courseData = Array(5)
  .fill({
    slug: "maths",
    coverImage:
      "https://media.wired.com/photos/64021deee9beb77b09e83b93/3:2/w_1280%2Cc_limit/Quanta_IntransitiveDice-byKristinaArmitage-Lede-scaled-copy.jpg",
    title: "Maths",
    category: "additional",
    minimumAge: 20,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla euismod, nisl vitae aliquam ultricies, nunc nisl ultricies nunc, vitae aliquam nisl nisl vitae aliquam ultricies, nunc nisl ultricies nunc, vitae aliquam nisl",
  })
  .map((c) => ({
    ...c,
    _id: Math.random() * 100000000000000000 * Math.random(),
  }));

export {
  SideBarMenu,
  registeredUsers,
  statsData,
  listOfTodayClasses,
  packagesData,
  courseData,
};
