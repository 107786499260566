/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Get } from "../../Axios/AxiosFunctions";
import { Button } from "../../Component/Button/Button";
import NoData from "../../Component/NoData/NoData";
import PaginationComponent from "../../Component/PaginationComponent";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import TableSkeleton from "../../Component/TableSkeleton";
import { BaseURL, recordsLimit } from "../../config/apiUrl";
import classes from "./AllClassRequest.module.css";
import { registeredUsers } from "../../config/DummyData";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const AllClassRequest = () => {
  const navigate = useNavigate();
  const { access_token, user } = useSelector((state) => state?.authReducer);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(registeredUsers);
  const [page, setPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);

  // getData
  const GetData = async (page) => {
    const url = BaseURL(
      `${user?.role}/all-class-requests?page=${page}&limit=${recordsLimit}`
    );
    setLoading(true);
    const response = await Get(url, access_token);
    if (response !== undefined) {
      setData(response?.data?.data);
      setTotalResults(response?.data?.totalCount);
    }
    setLoading(false);
  };

  useEffect(() => {
    GetData(page);
  }, [page]);

  return (
    <>
      <style>{`
      .table100-body{
        height:calc(100vh - 260px);
        max-height:calc(100vh - 260px);
        overflow-y:scroll;
      }
  @media screen and (max-width:1440px){
      .table100-head, .table100-body{
        width:1200px;
      }
      .table100.ver1{
        overflow-x:scroll !important;
      }
    }

      `}</style>
      <div>
        <SideBarSkeleton heading={"Class Requests"}>
          <Container className={classes.main}>
            {/* table */}
            <Row className={classes.row}>
              <Col md={12} className={classes.tableMain}>
                <div className="table100 ver1 m-b-110">
                  <div className="table100-head">
                    <table>
                      <thead>
                        <tr className="row100 head">
                          <th
                            className="cell100 column1"
                            style={{ width: "10%", textAlign: "left" }}
                          >
                            S.No
                          </th>
                          <th
                            className="cell100 column2"
                            style={{ width: "20%", textAlign: "left" }}
                          >
                            Student Name
                          </th>
                          <th
                            className="cell100 column2"
                            style={{ width: "20%", textAlign: "left" }}
                          >
                            Course
                          </th>
                          <th
                            className="cell100 column3"
                            style={{ width: "10%", textAlign: "left" }}
                          >
                            Total Classes
                          </th>
                          
                          <th
                            className="cell100 column4"
                            style={{ width: "15%", textAlign: "left" }}
                          >
                            Status
                          </th>

                          <th
                            className="cell100 column5"
                            style={{ width: "15%" }}
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                  {loading ? (
                    <TableSkeleton rowsCount={recordsLimit} colsCount={7} />
                  ) : (
                    <div className={`table100-body js-pscroll ps ps--active-y`}>
                      <table>
                        <tbody>
                          {data?.length > 0 ? (
                            data?.map((item, index) => (
                              <tr key={index} className="row100 body">
                                <td
                                  className="cell100 column1"
                                  style={{ width: "10%", textAlign: "left" }}
                                >
                                  {index + 1}
                                </td>
                                <td
                                  className="cell100 column2"
                                  style={{
                                    width: "20%",
                                    textAlign: "left",
                                  }}
                                >
                                  <div className={`${classes.descCol} t-t-c`}>
                                    {[
                                      item?.student?.firstName,
                                      item?.student?.lastName,
                                    ].join(" ")}
                                  </div>
                                </td>
                                <td
                                  className="cell100 column2"
                                  style={{
                                    width: "20%",
                                    textAlign: "left",
                                  }}
                                >
                                  <div className={classes.descCol}>
                                    {item?.subscription?.course?.title}
                                  </div>
                                </td>

                                <td
                                  className="cell100 column4"
                                  style={{ width: "10%", textAlign: "left" }}
                                >
                                  <div className={classes.descCol}>
                                    {item?.subscription?.classes?.length}
                                  </div>
                                </td>
                               
                                <td
                                  className="cell100 column4"
                                  style={{ width: "15%", textAlign: "left" }}
                                >
                                  <div className={classes.descCol}>
                                    {item?.classType}
                                  </div>
                                </td>
                                <td
                                  className="cell100 column5"
                                  style={{ width: "15%" }}
                                >
                                  <div className={classes.actions_btn}>
                                    <div title="Click to View Detail">
                                      <Button
                                        className={classes.viewDetailBtn}
                                        label={"Details"}
                                        onClick={() => {
                                          navigate(
                                            `/class/request/${item?._id}`
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <NoData text={"No Class Request Found"} />
                          )}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </Col>
              <Col md={12} className={classes.paginationDiv}>
                {totalResults > recordsLimit && (
                  <PaginationComponent
                    totalPages={Math.ceil(totalResults / recordsLimit)}
                    setCurrentPage={setPage}
                    currentPage={page}
                  />
                )}
              </Col>
            </Row>
          </Container>
        </SideBarSkeleton>
      </div>
    </>
  );
};
export default AllClassRequest;
