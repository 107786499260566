import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import NoData from "../../Component/NoData/NoData";
import { Button } from "../../Component/Button/Button";
import TableSkeleton from "../../Component/TableSkeleton";
import classes from "./SubscriptionPage.module.css";
import { useNavigate } from "react-router-dom";
import { BaseURL, recordsLimit } from "../../config/apiUrl";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import { Get } from "../../Axios/AxiosFunctions";
import { useSelector } from "react-redux";
import PaginationComponent from "../../Component/PaginationComponent";
// import { DropDown } from "../../Component/DropDown/DropDown";

const SubscriptionPage = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const { access_token: accessToken, user } = useSelector(
    (state) => state.authReducer
  );
  const [loading, setLoading] = useState(false);
  const [totalResults, setTotalResults] = useState(0);
  const [page, setPage] = useState(1);

  // Courses Data
  // const [coursesData, setCoursesData] = useState([]);

  // Status Options
  // const [course, setCourse] = useState();

  const baseUrl = BaseURL(`${user?.role}/`);

  const getData = async (pg = page) => {
    setLoading(true);
    const response = await Get(
      `${baseUrl}${
        user?.role == "parent"
          ? `my-children-subscriptions?page=${pg}&limit=${recordsLimit}`
          : `get-my-subscriptions?page=${pg}&limit=${recordsLimit}`
      }`,
      accessToken
    );
    setLoading(false);
    if (response) {
      setData(response?.data?.data);
      setTotalResults(response?.data?.totalCount);
    }
  };

  // const getAllCourses = async () => {
  //   const response = await Get(BaseURL("course/all"), accessToken);
  //   if (response) {
  //   }
  // };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <style>{`
      .table100-body{
        height:calc(100vh - 260px);
        max-height:calc(100vh - 260px);
        overflow-y:scroll;
      }
  @media screen and (max-width:1440px){
      .table100-head, .table100-body{
        width:1200px;
      }
      .table100.ver1{
        overflow-x:scroll !important;
      }
    }

      `}</style>
      <SideBarSkeleton heading={"Subscriptions"}>
        <Container className={classes.main}>
          <Row className={classes.row}>
            {/* <Col md={12}>
              <div className={"flex_end"}>
                <DropDown
                  options={}
                  setter={(e) => {
                    setPage(1);
                  }}
                  customStyle={{ minWidth: "180px" }}
                  value={status}
                />
              </div>
            </Col> */}
            <Col md={12} className={classes.tableMain}>
              <div className="table100 ver1 m-b-110">
                <div className="table100-head">
                  <table>
                    <thead>
                      <tr className="row100 head">
                        <th
                          className="cell100 column1"
                          style={{ width: "10%", textAlign: "start" }}
                        >
                          S.No
                        </th>

                        <th
                          className="cell100 column2"
                          style={{ width: "15%", textAlign: "start" }}
                        >
                          Student Name
                        </th>
                        <th
                          className="cell100 column3"
                          style={{ width: "15%", textAlign: "start" }}
                        >
                          {user.role == "teacher" ? "Parent" : "Teacher"} Name
                        </th>
                        <th
                          className="cell100 column4"
                          style={{ width: "15%", textAlign: "start" }}
                        >
                          No of Classes
                        </th>

                        <th
                          className="cell100 column5"
                          style={{ width: "15%", textAlign: "start" }}
                        >
                          Package Type
                        </th>
                        <th
                          className="cell100 column5"
                          style={{ width: "15%", textAlign: "start" }}
                        >
                          Course
                        </th>
                        <th
                          className="cell100 column5"
                          style={{ width: "15%", textAlign: "start" }}
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
                {loading ? (
                  <TableSkeleton rowsCount={recordsLimit} colsCount={7} />
                ) : (
                  <div className="table100-body js-pscroll ps ps--active-y">
                    <table>
                      <tbody>
                        {data?.length > 0 ? (
                          data?.map((item, index) => (
                            <tr className="row100 body" key={index}>
                              <td
                                className="cell100 column1"
                                style={{ width: "10%", textAlign: "left" }}
                              >
                                {index + 1}
                              </td>
                              <td
                                className="cell100 column2"
                                style={{
                                  width: "15%",
                                  textAlign: "left",
                                }}
                              >
                                <div className={classes.descCol}>
                                  {item?.student?.firstName}{" "}
                                  {item?.student?.lastName}
                                </div>
                              </td>
                              <td
                                className="cell100 column2"
                                style={{
                                  width: "15%",
                                  textAlign: "start",
                                }}
                              >
                                {user?.role == "teacher" &&
                                  item?.student?.parent?.firstName +
                                    " " +
                                    item?.student?.parent?.lastName}
                                {user?.role !== "teacher" &&
                                  (item?.teacher
                                    ? item?.teacher?.firstName +
                                      " " +
                                      item?.teacher?.lastName
                                    : "-")}
                              </td>

                              <td
                                className="cell100 column4"
                                style={{ width: "15%", textAlign: "left" }}
                              >
                                <div className={classes.descCol}>
                                  {item?.classes?.length}
                                </div>
                              </td>
                              <td
                                className="cell100 column4"
                                style={{ width: "15%", textAlign: "left" }}
                              >
                                <div className={classes.descCol}>
                                  {item?.package?.packageType + " " + "mins"}
                                </div>
                              </td>

                              <td
                                className="cell100 column4"
                                style={{ width: "15%", textAlign: "left" }}
                              >
                                <div className={classes.descCol}>
                                  {item?.course?.title}
                                </div>
                              </td>

                              <td
                                className="cell100 column5"
                                style={{ width: "15%", textAlign: "start" }}
                              >
                                <div className={classes.actions_btn}>
                                  <Button
                                    className={classes.viewDetailBtn}
                                    label={"Details"}
                                    onClick={() => {
                                      navigate({
                                        pathname: `/subscriptions/${item?._id}`,
                                      });
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <NoData text={"No Subscriptions Found"} />
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </Col>
            <Col md={12} className={classes.paginationDiv}>
              {totalResults > recordsLimit && (
                <PaginationComponent
                  totalPages={Math.ceil(totalResults / recordsLimit)}
                  setCurrentPage={(e) => {
                    setPage(e);
                    getData(e);
                  }}
                  currentPage={page}
                />
              )}
            </Col>
          </Row>
        </Container>
      </SideBarSkeleton>
    </div>
  );
};

export default SubscriptionPage;
