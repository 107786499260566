/* eslint-disable */

import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Get, Patch } from "../../Axios/AxiosFunctions";
import { Button } from "../../Component/Button/Button";
import { DropDown } from "../../Component/DropDown/DropDown";
import NoData from "../../Component/NoData/NoData";
import PaginationComponent from "../../Component/PaginationComponent";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import TableSkeleton from "../../Component/TableSkeleton";
import { BaseURL, recordsLimit } from "../../config/apiUrl";
import classes from "./AnnouncementPage.module.css";
import { registeredUsers } from "../../config/DummyData";
import { useNavigate } from "react-router-dom";
import ShowMoreShowLessText from "../../Component/ShowMoreShowLess/ShowMoreShowLessText";
import AnnouncementDetailModal from "../../modals/AnnouncementDetailModal";

const TaskPage = () => {
  const navigate = useNavigate();
  const { access_token, user } = useSelector((state) => state?.authReducer);
  const userData = useSelector((state) => state?.authReducer?.user);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const [show, setShow] = useState(false);
  const [page, setPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);

  const DummyData = [
    {
      startDate: "12-12-2021",
      endDate: "15-12-2021",
      status: "active",
      title: "Class 1",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla eget aliquam quam.",
      date: "12-12-2021",
    },
    {
      startDate: "12-12-2021",
      endDate: "15-12-2021",
      status: "active",
      title: "Class 1",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla eget aliquam quam.",
      date: "12-12-2021",
    },
    {
      startDate: "12-12-2021",
      endDate: "15-12-2021",
      status: "active",
      title: "Class 1",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla eget aliquam quam.",
      date: "12-12-2021",
    },
  ];

  // getData
  async function getData(pg = page) {
    const url = BaseURL(`announcement/all?page=${pg}&limit=${recordsLimit}`);
    setLoading(true);
    const response = await Get(url, access_token);
    if (response) {
      setData(response?.data?.data);
      setTotalResults(response?.data?.totalCount);
      setLoading(false);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <style>{`
      .table100-body{
        height:calc(100vh - 370px);
        overflow-y:scroll;
      }
  @media screen and (max-width:1440px){
      .table100-head, .table100-body{
        width:1200px;
      }
      .table100.ver1{
        overflow-x:scroll !important;
      }
    }

      `}</style>
      <div>
        <SideBarSkeleton heading={"Announcements"}>
          <Container className={classes.main}>
            {/* table */}
            <Row className={classes.row}>
              {/* <Col md={12}>
                <div className={classes.spBtw}>
                  <h5>All Announcements</h5>
                  <DropDown
                    options={statusOptions}
                    setter={(e) => {
                      setStatus(e);
                      setPage(1);
                    }}
                    customStyle={{ minWidth: "180px" }}
                    value={status}
                  />
                </div>
              </Col> */}
              <Col md={12} className={classes.tableMain}>
                <div className="table100 ver1 m-b-110">
                  <div className="table100-head">
                    <table>
                      <thead>
                        <tr className="row100 head">
                          <th
                            className="cell100 column1"
                            style={{ width: "16.5%", textAlign: "start" }}
                          >
                            S.No
                          </th>
                          <th
                            className="cell100 column2"
                            style={{ width: "16.5%", textAlign: "start" }}
                          >
                            Start Name
                          </th>
                          <th
                            className="cell100 column2"
                            style={{ width: "16.5%", textAlign: "start" }}
                          >
                            End Name
                          </th>
                          <th
                            className="cell100 column3"
                            style={{ width: "16.6%", textAlign: "start" }}
                          >
                            Title
                          </th>
                          <th
                            className="cell100 column4"
                            style={{ width: "16.5%", textAlign: "start" }}
                          >
                            Description
                          </th>

                          <th
                            className="cell100 column5"
                            style={{ width: "16.5%" }}
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                  {loading ? (
                    <TableSkeleton rowsCount={recordsLimit} colsCount={7} />
                  ) : (
                    <div className="table100-body js-pscroll ps ps--active-y">
                      <table>
                        <tbody>
                          {data?.length > 0 ? (
                            data?.map((item, index) => (
                              <tr className="row100 body" key={index}>
                                <td
                                  className="cell100 column1"
                                  style={{ width: "16.5%", textAlign: "left" }}
                                >
                                  {index + 1}
                                </td>
                                <td
                                  className="cell100 column2"
                                  style={{
                                    width: "16.6%",
                                    textAlign: "left",
                                  }}
                                >
                                  <div className={classes.descCol}>
                                    {moment(item?.startDate).format(
                                      "DD MMM YYYY"
                                    )}
                                  </div>
                                </td>
                                <td
                                  className="cell100 column2"
                                  style={{
                                    width: "16.6%",
                                    textAlign: "start",
                                  }}
                                >
                                  {moment(item?.endDate).format("DD MMM YYYY")}
                                </td>

                                <td
                                  className="cell100 column4"
                                  style={{ width: "16.6%", textAlign: "left" }}
                                >
                                  <div className={classes.descCol}>
                                    {item?.title}
                                  </div>
                                </td>
                                <td
                                  className="cell100 column4"
                                  style={{ width: "15%", textAlign: "left" }}
                                >
                                  <div className={classes.descCol}>
                                    {
                                      <ShowMoreShowLessText
                                        text={item?.description}
                                        visibility={50}
                                      />
                                    }
                                  </div>
                                </td>

                                <td
                                  className="cell100 column5"
                                  style={{ width: "16.5%" }}
                                >
                                  <div className={classes.actions_btn}>
                                    <Button
                                      className={classes.viewDetailBtn}
                                      label={"Details"}
                                      onClick={() => {
                                        setShow(true);
                                        setSelectedRow(item);
                                      }}
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <NoData text={"No Announcement Found"} />
                          )}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </Col>
              <Col md={12} className={classes.paginationDiv}>
                {!!data?.length && totalResults > recordsLimit && (
                  <PaginationComponent
                    totalPages={Math.ceil(totalResults / recordsLimit)}
                    setCurrentPage={(e) => {
                      setPage(e);
                      getData(e);
                    }}
                    currentPage={page}
                  />
                )}
              </Col>
            </Row>
          </Container>
        </SideBarSkeleton>
      </div>
      <AnnouncementDetailModal
        show={show}
        setShow={setShow}
        data={selectedRow}
      />
    </>
  );
};
export default TaskPage;
