import React from "react";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import { Col, Container, Row } from "react-bootstrap";
import classes from "./OtherIssuse.module.css";
import { useState } from "react";
import { Input } from "../../Component/Input/Input";
import { TextArea } from "../../Component/TextArea";
import { Button } from "../../Component/Button/Button";
import { toast } from "react-toastify";
import { BaseURL, apiHeader } from "../../config/apiUrl";
import LottieLoader from "../../Component/LottieLoader/LottieLoader";
import { Post } from "../../Axios/AxiosFunctions";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const OtherIssuse = () => {
  const navigate=useNavigate()
  const { access_token } = useSelector(
    (state) => state?.authReducer
  );

  const [subject, setSubject] = useState("");
  const [reason, setReason] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // registerComplainHandler
  const registerComplainHandler = async () => {
    const params = {
      complainType: "other",
      subject: reason,
      complain: reason,
    };

    if (params.subject.length < 10)
      return toast.warn(`Subject must be at least 10 characters long.`);
    if (params.complain.length < 10)
      return toast.warn(`Complain must be at least 50 characters long.`);

    const baseUrl = BaseURL("complain/create");

    setIsLoading(true);
    const response = await Post(baseUrl, params, apiHeader(access_token));
    setIsLoading(false);

    if (response) {
      toast.success(`Complain registered successfully.`);
navigate("/complain/all")
      setSubject("");
      setReason("");
    }
  };

  return (
    <>
      <SideBarSkeleton heading={"Register a Complaint"}>
        <div className={classes?.topContainer}>
          <Container className={classes.main}>
            <Row>
              <Col md={12}>
                <div className="mt-4">
                  <Input
                    label={"Subject"}
                    placeholder={"Enter Subject"}
                    value={subject}
                    setter={setSubject}
                  />
                </div>
              </Col>
              <Col md={12}>
                <div className="mt-4">
                  <TextArea
                    label={"Reason"}
                    placeholder={"Enter Reason"}
                    value={reason}
                    setter={setReason}
                  />
                </div>
              </Col>
              <Col md={12}>
                <div className="mt-4">
                  <Button
                    className={classes.btn}
                    label={"Submit"}
                    disabled={isLoading}
                    onClick={registerComplainHandler}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        {isLoading && <LottieLoader />}
      </SideBarSkeleton>
    </>
  );
};

export default OtherIssuse;
