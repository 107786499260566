import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

function BeforeLoginRoute({ element: Element }) {
  const isAuthenticated = useSelector((state) => state.authReducer.isLogin);
  const userRole = useSelector((state) => state?.authReducer?.user?.role);
  return (
    <>
      {!isAuthenticated ? (
        Element
      ) : (
        <Navigate
          replace
          to={
            userRole == "teacher"
              ? "/teacher/dashboard"
              : userRole == "parent"
              ? "/parent/dashboard"
              : "/student/dashboard"
          }
        />
      )}
    </>
  );
}
export default BeforeLoginRoute;
