/* eslint-disable */

import "./assets/Styles/style.css";
import "./assets/Styles/table.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.min.css";
import "react-modern-drawer/dist/index.css";
import "font-awesome/css/font-awesome.min.css";
import "react-quill/dist/quill.snow.css";
import "react-clock/dist/Clock.css";
import React, { Suspense, lazy, useEffect } from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ProtectedRouter from "./Helper/ProtectedRoute";
import { Loader } from "./Component/Loader";
import MyProfile from "./pages/MyProfile";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";
import { apiUrl, BaseURL } from "./config/apiUrl";
import { Beforeunload } from "react-beforeunload";
import Notifications from "./pages/Notifications";
import { saveChatCounter, saveNotiData } from "./store/common/commonSlice";
import { Get } from "./Axios/AxiosFunctions";
import { updateUser } from "./store/auth/authSlice";
import ClassRequestDetail from "./pages/ClassRequestDetail";
import ClassDetail from "./pages/ClassDetail";
import TeacherDashboard from "./pages/TeacherDashboard";
import AllClassRequest from "./pages/AllClassRequest";
import ListOfTodaysClasses from "./pages/ListOfTodaysClasses/ListOfTodaysClasses";
import AllClasses from "./pages/AllClasses";
import AllCourses from "./pages/AllCourses";
import BeforeLoginRoute from "./Helper/BeforeLoginRoute";
import AllLessons from "./pages/AllLessons";
import ComplainTeacher from "./pages/ComplainTeacher";
import OtherIssuse from "./pages/OtherIssuse";
import ComplaintRecords from "./pages/ComplaintRecords";
import TaskPage from "./pages/TaskPage";
import StudentDashboard from "./pages/StudentDashboard";
import AnnouncementPage from "./pages/AnnouncementPage";
import SubscriptionPage from "./pages/SubscriptionPage";
import SubscriptionDetailPage from "./pages/SubscriptionDetailPage";
import LessonLearningMaterial from "./pages/LessonLearningMaterial";
import Salary from "./pages/Salary";

const Login = lazy(() => import("./pages/Login"));
const PTDashboard = lazy(() => import("./pages/PTDashboard"));
const Transactions = lazy(() => import("./pages/Transactions"));
const AllUsers = lazy(() => import("./pages/AllUsers"));
const ListOfStudents = lazy(() =>
  import("./pages/ListOfStudents/ListOfStudents")
);
const PackagesScreen = lazy(() =>
  import("./pages/PackagesScreen/PackagesScreen")
);
const NotFound = lazy(() => import("./pages/NotFound"));
const AddOrEditStudentPage = lazy(() =>
  import("./pages/AddOrEditStudentPage/AddOrEditStudentPage")
);
const StudentScheduleAndCheckoutScreen = lazy(() =>
  import(
    "./pages/StudentScheduleAndCheckoutScreen/StudentScheduleAndCheckoutScreen"
  )
);
const UserDetailScreen = lazy(() =>
  import("./pages/UserDetailScreen/UserDetailScreen")
);

export let socket = null;
function App() {
  const {
    user,
    isLogin,
    access_token: accessToken,
  } = useSelector((state) => state?.authReducer);
  const userDataState = useSelector((state) => state?.authReducer?.user);
  const dispatch = useDispatch();

  const fetchNotificationsForCount = async () => {
    const url = BaseURL("notifications/all?page=1&limit=5&noCountUpdate=true");
    let response = await Get(url, accessToken);

    if (response !== undefined) {
      response?.data?.newNotifications > 0 &&
        dispatch(saveNotiData(response?.data?.newNotifications));
    }
  };

  const getMe = async () => {
    const url = BaseURL("users/me");
    const response = await Get(url, accessToken);

    if (response !== undefined) {
      const apiUser = response?.data?.data?.user;
      dispatch(updateUser(apiUser));
    }
  };

  useEffect(() => {
    if (isLogin) {
      // socket = io(apiUrl, {
      //   transports: ["websocket"],
      //   reconnection: true,
      //   reconnectionDelay: 180000,
      //   reconnectionDelayMax: 300000,
      // });
      // socket?.emit("join", userDataState?._id);
      // fetchNotificationsForCount();
      getMe();
    }
  }, [isLogin]);

  useEffect(() => {
    socket?.on("new-notification", (notification) => {
      //something went wrong here
      if (
        notification?.receiver == userDataState?._id &&
        window.location.pathname !== "/notifications" &&
        notification?.flag == "project"
      ) {
        dispatch(saveNotiData());
      }
      //something went wrong here

      if (
        notification?.receiver == userDataState?._id &&
        notification?.flag == "chat" &&
        window.location.pathname !== "/chat"
      ) {
        dispatch(saveChatCounter(notification));
        dispatch(saveNotiData());
      }
    });
    return () => {
      socket?.off("new-notification");
    };
  }, []);

  return (
    <>
      <ToastContainer />
      <Beforeunload
        onBeforeunload={() => {
          if (isLogin) {
            socket?.emit("disconnected", user?._id);
          }
        }}
      />
      <BrowserRouter>
        <Suspense fallback={<Loader className={"mvh-100"} />}>
          <Routes>
            <Route
              path="/"
              exact
              element={<BeforeLoginRoute element={<Login />} />}
            />
            {/* teacher routes Start */}
            <Route
              path="/teacher/dashboard"
              exact
              element={<ProtectedRouter element={<TeacherDashboard />} />}
            />
            {/* teacher routes End */}

            {/* classes Route Start */}
            <Route
              path="/class/all"
              exact
              element={<ProtectedRouter element={<AllClasses />} />}
            />
            <Route
              path="/class/:id"
              exact
              element={<ProtectedRouter element={<ClassDetail />} />}
            />
            <Route
              path="/class/today"
              exact
              element={<ProtectedRouter element={<ListOfTodaysClasses />} />}
            />
            <Route
              path="/class/request"
              exact
              element={<ProtectedRouter element={<AllClassRequest />} />}
            />
            <Route
              path="/class/request/:id"
              exact
              element={<ProtectedRouter element={<ClassRequestDetail />} />}
            />
            {/* classes Route End */}
            {/* Course Route Start */}
            <Route
              path="/course/all"
              exact
              element={<ProtectedRouter element={<AllCourses />} />}
            />
            <Route
              path="/course/:courseSlug"
              exact
              element={<ProtectedRouter element={<AllLessons />} />}
            />
            <Route
              path="/course/:courseSlug/:lessonSlug"
              exact
              element={<ProtectedRouter element={<LessonLearningMaterial />} />}
            />
            {/* Course Route End */}
            {/* parents routes */}
            {/* student routes */}

            <Route
              path="/student/dashboard"
              exact
              element={<ProtectedRouter element={<StudentDashboard />} />}
            />
            <Route
              path="/parent/dashboard"
              exact
              element={<ProtectedRouter element={<PTDashboard />} />}
            />
            <Route
              path="/student/all"
              exact
              element={<ProtectedRouter element={<ListOfStudents />} />}
            />
            <Route
              path="/package"
              exact
              element={<ProtectedRouter element={<PackagesScreen />} />}
            />
            <Route
              path="/student/new"
              exact
              element={<ProtectedRouter element={<AddOrEditStudentPage />} />}
            />
            <Route
              path="/student/edit"
              exact
              element={<ProtectedRouter element={<AddOrEditStudentPage />} />}
            />
            <Route
              path="/package/checkout"
              exact
              element={
                <ProtectedRouter
                  element={<StudentScheduleAndCheckoutScreen />}
                />
              }
            />

            <Route
              path="/transactions"
              exact
              element={<ProtectedRouter element={<Transactions />} />}
            />
            <Route
              path="/all-users"
              exact
              element={<ProtectedRouter element={<AllUsers />} />}
            />

            <Route
              path="/user/:id"
              exact
              element={<ProtectedRouter element={<UserDetailScreen />} />}
            />

            <Route
              path="/my-profile"
              exact
              element={<ProtectedRouter element={<MyProfile />} />}
            />

            <Route
              path="/notifications"
              exact
              element={<ProtectedRouter element={<Notifications />} />}
            />
            <Route
              path="/complain/teacher"
              exact
              element={<ProtectedRouter element={<ComplainTeacher />} />}
            />
            <Route
              path="/complain/other"
              exact
              element={<ProtectedRouter element={<OtherIssuse />} />}
            />
            <Route
              path="/complain/all"
              exact
              element={<ProtectedRouter element={<ComplaintRecords />} />}
            />
            <Route
              path="/subscriptions"
              exact
              element={<ProtectedRouter element={<SubscriptionPage />} />}
            />
            <Route
              path="/subscriptions/:id"
              exact
              element={<ProtectedRouter element={<SubscriptionDetailPage />} />}
            />
            <Route
              path="/task/all"
              exact
              element={<ProtectedRouter element={<TaskPage />} />}
            />
            <Route
              path="/announcement/all"
              exact
              element={<ProtectedRouter element={<AnnouncementPage />} />}
            />
            <Route
              path="/salaries"
              exact
              element={<ProtectedRouter element={<Salary />} />}
            />

            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;
