import React, { useEffect, useRef, useState } from "react";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import { Col, Container, Row } from "react-bootstrap";
import classes from "./SubscriptionDetailPage.module.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Get } from "../../Axios/AxiosFunctions";
import { useSelector } from "react-redux";
import LottieLoader from "../../Component/LottieLoader/LottieLoader";
import { BaseURL, imageUrl, recordsLimit } from "../../config/apiUrl";
import moment from "moment";
import CourseCard from "../../Component/CourseCard/CourseCard";
import PackageComponent from "../../Component/PackageComponent/PackageComponent";

import TableSkeleton from "../../Component/TableSkeleton";
import NoData from "../../Component/NoData/NoData";
import { Button } from "../../Component/Button/Button";

const SubscriptionDetailPage = () => {
  const navigate = useNavigate();
  const classId = useParams().id;
  const { access_token: accessToken } = useSelector(
    (state) => state.authReducer
  );
  const [isLoading, setIsLoading] = useState(false);
  const [classData, setClassData] = useState(null);
  const [height, setHeight] = useState(0);
  const ref = useRef(null);

  console.log(classData, "classData classData classData");

  // getClassDetail
  const getClassDetail = async () => {
    const url = BaseURL(`users/subscription-detail/${classId} `);
    setIsLoading(true);
    const response = await Get(url, accessToken);
    setIsLoading(false);

    if (response) {
      setClassData(response?.data?.data);
    }
  };

  useEffect(() => {
    getClassDetail();
  }, []);

  useEffect(() => {
    setHeight(ref.current.clientHeight);
  }, [isLoading]);
  console.log(height, "height height height");
  return (
    <>
    <style>{`
      .table100-body{
        // height:calc(100vh - 260px);
        // max-height:calc(100vh - 260px);
        overflow-y:scroll;
      }
  @media screen and (max-width:1440px){
      .table100-head, .table100-body{
        width:1200px;
      }
      .table100.ver1{
        overflow-x:scroll !important;
      }
    }

      `}</style>
      <SideBarSkeleton heading={"Subscription Details"}>
        <div className={classes?.topContainer}>
          <Container className={classes.main}>
            <Row
              style={{
                alignItems: "end",
              }}
            >
              {/* <Col md={12}>
                <div className={classes.top_head_div}>
                  <h3>
                    <b>Subscription Details</b>
                  </h3>
                </div>
              </Col> */}
              <Col md={12}>
                <h5 className={classes?.mainHeading}>Student Detail</h5>
              </Col>
              <Col md={3}>
                <div className={classes.user_left_card}>
                  <div className={classes.img_wrapper}>
                    <img
                      src={imageUrl(classData?.student?.photo)}
                      alt="student"
                    />
                  </div>

                  <div className={classes.user_info}>
                    <h5>
                      {classData?.student?.firstName}{" "}
                      {classData?.student?.lastName}
                    </h5>
                    <p>
                      <b>Age:</b> {classData?.student?.age}
                    </p>
                  </div>
                </div>
              </Col>
              <Col md={9}>
                <div className={classes.user_left_card}>
                  <Row>
                    <Col md={7}>
                      <div className={`${classes?.singleItem} text-start`}>
                        <b>Email</b>
                        <p>{classData?.student?.email}</p>
                      </div>
                    </Col>
                    <Col md={5}>
                      <div className={`${classes?.singleItem} text-start`}>
                        <b>Phone:</b>
                        <p>{classData?.student?.phone}</p>
                      </div>
                    </Col>
                    <Col md={7}>
                      <div className={`${classes?.singleItem} text-start`}>
                        <b>Alt Phone:</b>
                        <p>{classData?.student?.altPhone}</p>
                      </div>
                    </Col>
                    <Col md={5}>
                      <div className={`${classes?.singleItem} text-start`}>
                        <b>Nationality:</b>
                        <p>{classData?.student?.nationality}</p>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className={`${classes?.singleItem} text-start`}>
                        <b>Gender:</b>
                        <p>{classData?.student?.gender}</p>
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className={`${classes?.singleItem} text-start`}>
                        <b>Address:</b>
                        <p>{classData?.student?.address || "N/A"}</p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            {classData?.teacher && <Row className={classes.second_detail_row}>
              <Col md={12}>
                <h5 className={classes?.mainHeading}>Teacher Details</h5>
              </Col>
              <Col md={3}>
                <div className={classes.user_left_card}>
                  <div className={classes.img_wrapper}>
                    <img
                      src={imageUrl(classData?.teacher?.photo)}
                      alt="teacher"
                    />
                  </div>

                  <div className={classes.user_info}>
                    <h5>
                      {classData?.teacher?.firstName}{" "}
                      {classData?.teacher?.lastName}
                    </h5>
                    <p>
                      <b>Age:</b> {classData?.teacher?.age}
                    </p>
                  </div>
                </div>
              </Col>
              <Col md={9}>
                <div className={classes.user_left_card}>
                  <Row>
                    <Col md={7}>
                      <div className={`${classes?.singleItem} text-start`}>
                        <b>Email:</b>
                        <p>{classData?.teacher?.email}</p>
                      </div>
                    </Col>
                    <Col md={5}>
                      <div className={`${classes?.singleItem} text-start`}>
                        <b>Phone:</b>
                        <p>{classData?.teacher?.phone}</p>
                      </div>
                    </Col>
                    <Col md={7}>
                      <div className={`${classes?.singleItem} text-start`}>
                        <b>Alt Phone:</b>
                        <p>{classData?.teacher?.altPhone}</p>
                      </div>
                    </Col>
                    <Col md={5}>
                      <div className={`${classes?.singleItem} text-start`}>
                        <b>Nationality:</b>
                        <p>{classData?.teacher?.nationality}</p>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className={`${classes?.singleItem} text-start`}>
                        <b>Gender:</b>
                        <p>{classData?.teacher?.gender}</p>
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className={`${classes?.singleItem} text-start`}>
                        <b>Address:</b>
                        <p>{classData?.teacher?.address || "N/A"}</p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>}

            <Row
              ref={ref}
              className={`${classes.second_detail_row} ${classes.newRow}`}
            >
              <Col md={4}>
                <h5 className={classes?.mainHeading}>Package Detail</h5>
                {/* <Col md={4}> */}
                <div
                  className={classes.dummyDiv}
                  style={{
                    minHeight: height - 35,
                  }}
                >
                  <PackageComponent
                    data={classData?.package}
                    btnVisibility={false}
                    onClick={() => {}}
                  />
                </div>
                {/* </Col> */}
              </Col>
              <Col md={4}>
                <h5 className={classes?.mainHeading}>Course Detail</h5>
                <div
                  className={classes.dummyDiv}
                  style={{
                    minHeight: height - 35,
                  }}
                >
                  {console.log(classData?.subscription?.course?.slug,'test')}
                  <CourseCard data={classData?.course} 
                    onClick={() => {
                      navigate(`/course/${classData?.course?.slug}`)
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="table100 ver1 m-b-110 mt-5">
                  <div className="table100-head">
                    <table>
                      <thead>
                        <tr className="row100 head">
                          <th
                            className="cell100 column1"
                            style={{ width: "10%", textAlign: "start" }}
                          >
                            S.No
                          </th>

                          <th
                            className="cell100 column3"
                            style={{ width: "15%", textAlign: "start" }}
                          >
                            Teacher Name
                          </th>

                          <th
                            className="cell100 column5"
                            style={{ width: "15%", textAlign: "start" }}
                          >
                            Class Type
                          </th>
                          <th
                            className="cell100 column5"
                            style={{ width: "15%", textAlign: "start" }}
                          >
                            Date
                          </th>
                          <th
                            className="cell100 column5"
                            style={{ width: "10%", textAlign: "start" }}
                          >
                            Start Time
                          </th>
                          <th
                            className="cell100 column5"
                            style={{ width: "10%", textAlign: "start" }}
                          >
                            End Time
                          </th>
                          <th
                            className="cell100 column5"
                            style={{ width: "15%", textAlign: "start" }}
                          >
                            Status
                          </th>
                          <th
                            className="cell100 column5"
                            style={{ width: "10%", textAlign: "start" }}
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                  {isLoading ? (
                    <TableSkeleton rowsCount={recordsLimit} colsCount={7} />
                  ) : (
                    <div className="table100-body js-pscroll ps ps--active-y">
                      <table>
                        <tbody>
                          {classData?.classes?.length > 0 ? (
                            classData?.classes?.map((item, index) => (
                              <tr className="row100 body" key={index}>
                                <td
                                  className="cell100 column1"
                                  style={{ width: "10%", textAlign: "left" }}
                                >
                                  {index + 1}
                                </td>
                                <td
                                  className="cell100 column2"
                                  style={{
                                    width: "15%",
                                    textAlign: "left",
                                  }}
                                >
                                  <div className={classes.descCol}>
                                    {item?.teacher ? <Link
                                      style={{ color: "#000", fontWeight: 600 }}
                                      to={`/user/${item?.teacher?.slug}`}
                                    >
                                      {item?.teacher?.firstName + " " + item?.teacher?.lastName}
                                    </Link>:"-"}
                                  </div>
                                </td>
                                <td
                                  className="cell100 column2"
                                  style={{
                                    width: "15%",
                                    textAlign: "start",
                                  }}
                                >
                                  {item?.classType}
                                </td>

                                <td
                                  className="cell100 column4"
                                  style={{ width: "15%", textAlign: "left" }}
                                >
                                  <div className={classes.descCol}>
                                    {moment(item?.date).format("DD-MM-YYYY")}
                                  </div>
                                </td>
                                <td
                                  className="cell100 column4"
                                  style={{ width: "10%", textAlign: "left" }}
                                >
                                  <div className={classes.descCol}>
                                    {item?.startTime}
                                  </div>
                                </td>

                                <td
                                  className="cell100 column4"
                                  style={{ width: "10%", textAlign: "left" }}
                                >
                                  <div className={classes.descCol}>
                                    {item?.endTime}
                                  </div>
                                </td>
                                <td
                                  className="cell100 column4"
                                  style={{ width: "15%", textAlign: "left" }}
                                >
                                  <div className={classes.descCol}>
                                    {item?.status}
                                  </div>
                                </td>
                                <td
                                  className="cell100 column5"
                                  style={{ width: "10%", textAlign: "start" }}
                                >
                                  <Button
                                    onClick={() =>
                                      navigate(`/class/${item?._id}`)
                                    }
                                  >
                                    View
                                  </Button>
                                </td>

                                {/* <td
                                  className="cell100 column5"
                                  style={{ width: "15%", textAlign: "start" }}
                                >
                                  <div className={classes.actions_btn}>
                                    <Button
                                      className={classes.viewDetailBtn}
                                      label={"Details"}
                                      onClick={() => {
                                        navigate({
                                          pathname: `/subscriptions/${item?._id}`,
                                        });
                                      }}
                                    />
                                  </div>
                                </td> */}
                              </tr>
                            ))
                          ) : (
                            <NoData text={"No Classes Found"} />
                          )}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </SideBarSkeleton>

      {isLoading && <LottieLoader />}
    </>
  );
};

export default SubscriptionDetailPage;
