/* eslint-disable */

import React, { useState } from "react";
import classes from "./ComplaintDetailModal.module.css";
import { Col, Row } from "react-bootstrap";
import ModalSkeleton from "../ModalSkeleton";
import { toast } from "react-toastify";
import { formRegEx, formRegExReplacer } from "../../config/apiUrl";

import { Button } from "../../Component/Button/Button";
import ShowMoreShowLessText from "../../Component/ShowMoreShowLess/ShowMoreShowLessText";

const ComplaintDetailModal = ({
  show,
  setShow,
  handleSubmit,

  selectedData,
}) => {
  console.log(selectedData, "selectedData selectedData selectedData");

  return (
    <div>
      <ModalSkeleton
        show={show}
        setShow={setShow}
        width="1000px"
        borderRadius="20px"
        header={`Complain Details`}
      >
        <div className={classes.addProjectModal_main}>
          <Row className={classes.addProject_row}>
            {selectedData?.student?.firstName && (
              <Col md={6}>
                <p className="t-t-c">
                  <b>Student Name: </b> {selectedData?.student?.firstName}{" "}
                  {selectedData?.student?.lastName}
                </p>
              </Col>
            )}
            {selectedData?.teacher?.firstName && (
              <Col md={6}>
                <p className="t-t-c">
                  <b> Teacher Name: </b> {selectedData?.teacher?.firstName}{" "}
                  {selectedData?.teacher?.lastName}
                </p>
              </Col>
            )}
            <Col md={6}>
              <p className="t-t-c">
                <b> Complain Type: </b> {selectedData?.complainType}{" "}
              </p>
            </Col>
            {selectedData?.course?.title && (
              <Col md={6}>
                <p className="t-t-c">
                  <b>Course: </b> {selectedData?.course?.title}
                </p>
              </Col>
            )}
            <Col md={6}>
              <p className="t-t-c">
                <b>Status: </b> {selectedData?.status}{" "}
              </p>
            </Col>
            <Col md={6}>
              <p>
                <b> Subject: </b>{" "}
                {
                  <ShowMoreShowLessText
                    text={selectedData?.subject}
                    visibility={100}
                  />
                }{" "}
              </p>
            </Col>
            <Col md={12}>
              <p>
                <b> Complain: </b>{" "}
                {
                  <ShowMoreShowLessText
                    text={selectedData?.complain}
                    visibility={100}
                  />
                }{" "}
              </p>
            </Col>
          </Row>
          <div className={classes.btn_main}>
            <Button
              onClick={() => setShow(false)}
              className={classes.btn}
              label={"Close"}
            />
          </div>
        </div>
      </ModalSkeleton>
    </div>
  );
};

export default ComplaintDetailModal;
