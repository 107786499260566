// /* eslint-disable */

// import React, { useState } from "react";
// import classes from "./AddOrEditLessonMaterialModal.module.css";
// import { Col, Row } from "react-bootstrap";
// import ModalSkeleton from "../ModalSkeleton";
// import { toast } from "react-toastify";
// import { formRegEx, formRegExReplacer } from "../../config/apiUrl";
// import UploadMultiplePDF from "../../Component/UploadMultiplePDF/UploadMultiplePDF";
// import { Button } from "../../Component/Button/Button";

// const AddOrEditLessonMaterialModal = ({
//   show,
//   setShow,
//   handleSubmit,
//   isLoading = false,
//   data,
//   type,
// }) => {
//   const [selectedPDFFiles, setSelectedPDFFiles] = useState([]);

//   const HandleSubmitData = async () => {
//     let params = {
//       // title,
//       // description,
//     };

//     for (let key in params) {
//       if (
//         params[key] == "" ||
//         params[key] == null ||
//         params[key] == undefined
//       ) {
//         return toast.error(
//           `Please fill the ${key
//             .replace(formRegEx, formRegExReplacer)
//             .toLowerCase()} field!`
//         );
//       }
//     }
//     await handleSubmit(params);
//   };
//   return (
//     <div>
//       <ModalSkeleton
//         show={show}
//         setShow={setShow}
//         width="700px"
//         borderRadius="20px"
//         header={`${data ? "Edit" : "Add"} Lesson Material`}
//       >
//         <div className={classes.addProjectModal_main}>
//           <Row className={classes.addProject_row}>
//             <Col md={12}>
//               {/* {type == "pdf" && ( */}
//               <UploadMultiplePDF
//                 state={selectedPDFFiles}
//                 setter={setSelectedPDFFiles}
//               />
//               {/* )} */}
//             </Col>
//           </Row>
//           <div className={classes.btn_main}>
//             <Button
//               onClick={HandleSubmitData}
//               className={classes.btn}
//               label={isLoading ? "Submitting..." : "Submit"}
//               disabled={isLoading}
//             />
//           </div>
//         </div>
//       </ModalSkeleton>
//     </div>
//   );
// };

// export default AddOrEditLessonMaterialModal;

/* eslint-disable */

import React, { useState } from "react";
import classes from "./AddOrEditLessonMaterialModal.module.css";
import { Col, Row } from "react-bootstrap";
import { Button } from "../../Component/Button/Button";
import ModalSkeleton from "../ModalSkeleton";
import { toast } from "react-toastify";
import {
  CreateFormData,
  formRegEx,
  formRegExReplacer,
} from "../../config/apiUrl";
import UploadMultiplePdf from "../../Component/UploadMultiplePDF/UploadMultiplePDF";
import UploadImageBox from "../../Component/UploadImageBox";
import UploadVideoBox from "../../Component/UploadVideoBox";

const AddLessonMaterialModal = ({
  show,
  setShow,
  handleSubmit,
  isLoading = false,
  type,
  title = "",
  isMultiple = true,
}) => {
  const [selectedPDFFiles, setSelectedPDFFiles] = useState(
    isMultiple ? [] : null
  );
  const [selectedVideoFiles, setSelectedVideoFiles] = useState(
    isMultiple ? [] : null
  );
  const [selectedImageFiles, setSelectedImageFiles] = useState(
    isMultiple ? [] : null
  );

  const HandleSubmitData = async () => {
    let params = {
      ...(type == "pdf" && { pdf: selectedPDFFiles }),
      ...(type == "image" && { image: selectedImageFiles }),
      ...(type == "video" && { video: selectedVideoFiles }),
    };

    for (let key in params) {
      if (
        params[key] == "" ||
        params[key] == null ||
        params[key] == undefined
      ) {
        return toast.error(
          `Please fill the ${key
            .replace(formRegEx, formRegExReplacer)
            .toLowerCase()} field!`
        );
      }
    }
    const formData = await CreateFormData(params);
    await handleSubmit(formData);
  };
  return (
    <div>
      <ModalSkeleton
        show={show}
        setShow={setShow}
        width="700px"
        borderRadius="20px"
        header={title}
      >
        <div className={classes.addProjectModal_main}>
          <Row className={classes.addProject_row}>
            <Col md={12}>
              {type == "pdf" && (
                <UploadMultiplePdf
                  state={selectedPDFFiles}
                  setter={setSelectedPDFFiles}
                  isMultiple={isMultiple}
                />
              )}
              {type == "image" && (
                <UploadImageBox
                  state={selectedImageFiles}
                  setter={setSelectedImageFiles}
                  isMultiple={isMultiple}
                />
              )}
              {type == "video" && (
                <UploadVideoBox
                  state={selectedVideoFiles}
                  setter={setSelectedVideoFiles}
                  isMultiple={isMultiple}
                />
              )}
            </Col>
          </Row>
          <div className={classes.btn_main}>
            <Button
              onClick={HandleSubmitData}
              className={classes.btn}
              label={isLoading ? "Submitting..." : "Submit"}
              disabled={isLoading}
            />
          </div>
        </div>
      </ModalSkeleton>
    </div>
  );
};

export default AddLessonMaterialModal;
