/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import { Col, Container, Row } from "react-bootstrap";
import classes from "./ClassDetail.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { Get, Post } from "../../Axios/AxiosFunctions";
import { useSelector } from "react-redux";
import LottieLoader from "../../Component/LottieLoader/LottieLoader";
import { BaseURL, apiHeader, imageUrl } from "../../config/apiUrl";
import moment from "moment";
import CourseCard from "../../Component/CourseCard/CourseCard";
import PackageComponent from "../../Component/PackageComponent/PackageComponent";
import AddOrEditLessonMaterialModal from "../../modals/AddOrEditLessonMaterialModal";
import { Button } from "../../Component/Button/Button";
import { toast } from "react-toastify";
import RenderMediaModal from "../../modals/RenderMediaModal";
import MarkAsCompletedModal from "../../modals/MarkAsCompletedModal";

const ClassDetail = () => {
  const classId = useParams().id;
  const navigate=useNavigate()
  const { access_token: accessToken, user } = useSelector(
    (state) => state.authReducer
  );
  const [isLoading, setIsLoading] = useState(false);
  const [classData, setClassData] = useState({});
  const [assignments, setAssignments] = useState([]);
  const [height, setHeight] = useState(0);
  const ref = useRef(null);

  // Modal API's states
  const [show, setShow] = useState("");
  const [isModalLoading, setIsModalLoading] = useState("");

  // getClassDetail
  const getClassDetail = async () => {
    const url = BaseURL(`class/detail/${classId}`);
    setIsLoading(true);
    const response = await Get(url, accessToken);
    setIsLoading(false);

    if (response) {
      setClassData(response?.data?.data);
      setAssignments(response?.data?.data?.assignments);
    }
  };

  // For Assignment Upload
  const uploadOrSubmitAssignment = async (e) => {
    const url = BaseURL(
      user?.role == "student"
        ? `student/submit-assignment`
        : `teacher/post-assignment`
    );
    setIsModalLoading("upload");
    if (user?.role == "student") {
      e?.append("assignmentId", assignments[0]?._id);
    } else {
      e?.append("classId", classId);
    }
    const response = await Post(url, e, apiHeader(accessToken, true));
    setIsModalLoading("");
    if (response) {
      setAssignments([response?.data?.data]);
      toast.success("Assignment uploaded successfully");
      setShow("");
    }
  };

  // For Mark as Complete Class
  const markAsCompleteClass = async (e) => {
    const url = BaseURL(`teacher/markclassCompleted`);
    const body = {
      classId,
      isAttended: e,
    };
    setIsModalLoading("mark-complete");
    const response = await Post(url, body, apiHeader(accessToken));
    setIsModalLoading("");
    if (response) {
      setClassData(response?.data?.data);
      toast.success("Class completed successfully");
      setShow("");
    }
  };

  useEffect(() => {
    getClassDetail();
  }, []);

  useEffect(() => {
    setHeight(ref.current.clientHeight);
  }, [isLoading]);

  const classEndDateWithTime = new Date(moment(classData?.date).format());
  classEndDateWithTime.setHours(classData?.endTime?.slice(0, 2));
  classEndDateWithTime.setMinutes(classData?.endTime?.slice(3));

  return (
    <>
      <style>{`
      .table100-body{
        height:calc(100vh - 260px);
        max-height:calc(100vh - 260px);
        overflow-y:scroll;
      }
  @media screen and (max-width:1440px){
      .table100-head, .table100-body{
        width:1200px;
      }
      .table100.ver1{
        overflow-x:scroll !important;
      }
    }

      `}</style>
      <SideBarSkeleton heading={"Class Details"}>
        <div className={classes?.topContainer}>
          <Container className={classes.main}>
            <Row
              style={{
                alignItems: "end",
              }}
            >
              {/* <Col md={12}>
                <div className={classes.top_head_div}>
                  <h3>
                    <b>Class Details</b>
                  </h3>
                </div>
              </Col> */}

              <Col
                md={12}
                className={[classes.jEnd, classes.mb20, classes.btnWrap].join(
                  " "
                )}
              >
                {user?.role == "teacher" &&
                  classData?.status == "pending" &&
                  moment().format() >=
                    moment(classEndDateWithTime).format() && (
                    <Button onClick={() => setShow("mark-complete")}>
                      Mark as Complete
                    </Button>
                  )}
                {classData?.status == "completed" && (
                  <>
                    {((user?.role == "student" &&
                      assignments?.length > 0 &&
                      !assignments[0]?.submittedDocument) ||
                      (user?.role == "teacher" &&
                        assignments?.length == 0)) && (
                      <Button
                        onClick={() => setShow("showUpload")}
                        className={classes?.addBtn}
                        label={`${
                          user?.role == "student" ? "Submit" : "Upload"
                        } Quiz/Assignment`}
                      />
                    )}
                    {assignments?.length > 0 &&
                      classData?.classType !== "trial" && (
                        <Button
                          onClick={() => setShow("viewMaterial")}
                          className={classes?.addBtn}
                          label={"View Quiz/Assignment"}
                        />
                      )}
                    {assignments?.length > 0 &&
                      !!assignments[0]?.submittedDocument && (
                        <Button
                          onClick={() => setShow("viewSubmission")}
                          className={classes?.addBtn}
                          label={
                            user?.role == "teacher"
                              ? "View Submission"
                              : "View My Submission"
                          }
                        />
                      )}
                  </>
                )}
              </Col>
              <Col md={12}>
                <h5 className={classes?.mainHeading}>Student Details</h5>
              </Col>
              <Col md={3}>
                <div className={classes.user_left_card}>
                  <div className={classes.img_wrapper}>
                    <img
                      src={imageUrl(classData?.student?.photo)}
                      alt="student"
                    />
                  </div>

                  <div className={classes.user_info}>
                    <h5 className="t-t-c">
                      {classData?.student?.firstName}{" "}
                      {classData?.student?.lastName}
                    </h5>
                    <p>
                      <b>Age:</b> {classData?.student?.age}
                    </p>
                  </div>
                </div>
              </Col>

              <Col md={9}>
                <div className={classes.user_left_card}>
                  <Row>
                    <Col md={7}>
                      <div className={`${classes?.singleItem} text-start`}>
                        <b>Email</b>
                        <p>{classData?.student?.email}</p>
                      </div>
                    </Col>
                    <Col md={5}>
                      <div className={`${classes?.singleItem} text-start`}>
                        <b>Phone:</b>
                        <p>{classData?.student?.phone}</p>
                      </div>
                    </Col>
                    <Col md={7}>
                      <div className={`${classes?.singleItem} text-start`}>
                        <b>Alt Phone:</b>
                        <p>{classData?.student?.altPhone}</p>
                      </div>
                    </Col>
                    <Col md={5}>
                      <div className={`${classes?.singleItem} text-start`}>
                        <b>Nationality:</b>
                        <p className="t-t-c">{classData?.student?.nationality}</p>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className={`${classes?.singleItem} text-start`}>
                        <b>Gender:</b>
                        <p>{classData?.student?.gender}</p>
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className={`${classes?.singleItem} text-start`}>
                        <b>Address:</b>
                        <p className="t-t-c">{classData?.student?.address || "N/A"}</p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            {classData?.teacher && <Row className={classes.second_detail_row}>
              <Col md={12}>
                <h5 className={classes?.mainHeading}>Teacher Details</h5>
              </Col>
              <Col md={3}>
                <div className={classes.user_left_card}>
                  <div className={classes.img_wrapper}>
                    <img
                      src={imageUrl(classData?.teacher?.photo)}
                      alt="teacher"
                    />
                  </div>

                  <div className={classes.user_info}>
                    <h5 className="t-t-c">
                      {classData?.teacher?.firstName}{" "}
                      {classData?.teacher?.lastName}
                    </h5>
                    <p>
                      <b>Age:</b> {classData?.teacher?.age}
                    </p>
                  </div>
                </div>
              </Col>
              <Col md={9}>
                <div className={classes.user_left_card}>
                  <Row>
                    <Col md={7}>
                      <div className={`${classes?.singleItem} text-start`}>
                        <b>Email:</b>
                        <p>{classData?.teacher?.email}</p>
                      </div>
                    </Col>
                    <Col md={5}>
                      <div className={`${classes?.singleItem} text-start`}>
                        <b>Phone:</b>
                        <p>{classData?.teacher?.phone}</p>
                      </div>
                    </Col>
                    <Col md={7}>
                      <div className={`${classes?.singleItem} text-start`}>
                        <b>Alt Phone:</b>
                        <p>{classData?.teacher?.altPhone}</p>
                      </div>
                    </Col>
                    <Col md={5}>
                      <div className={`${classes?.singleItem} text-start`}>
                        <b>Nationality:</b>
                        <p className="t-t-c">{classData?.teacher?.nationality}</p>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className={`${classes?.singleItem} text-start`}>
                        <b>Gender:</b>
                        <p className="t-t-c">{classData?.teacher?.gender}</p>
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className={`${classes?.singleItem} text-start`}>
                        <b>Address:</b>
                        <p className="t-t-c">{classData?.teacher?.address || "N/A"}</p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>}
            <Row
              ref={ref}
              className={`${classes.second_detail_row} ${classes.newRow}`}
            >
              <Col md={4}>
                <h5 className={classes?.mainHeading}>Class Details</h5>
                <div
                  className={classes.dummyDiv}
                  // style={{
                  //   minHeight: height - 35,
                  // }}
                >
                  <div className={classes.classDetailContainer}>
                    <Row>
                      <Col md={12}>
                        <div className={`${classes?.singleItem} text-start`}>
                          <b>Class Date:</b>
                          <p>
                            {moment(classData?.date).format("Do, dd MMM YYYY")}
                          </p>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className={`${classes?.singleItem} text-start`}>
                          <b>Class Time:</b>
                          <p>
                            {moment(classData?.startTime, "HH:mm").format(
                              "hh:mm A"
                            )}
                          </p>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className={`${classes?.singleItem} text-start`}>
                          <b>Class Type:</b>
                          <p className="t-t-c">{classData?.classType}</p>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className={`${classes?.singleItem} text-start`}>
                          <b>Status:</b>
                          <p className="t-t-c">{classData?.status}</p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <h5 className={classes?.mainHeading}>Package Details</h5>
                {/* <Col md={4}> */}
                <div
                  className={classes.dummyDiv}
                  style={
                    {
                      // minHeight: height - 35,
                    }
                  }
                >
                  <PackageComponent
                    data={classData?.subscription?.package}
                    btnVisibility={false}
                    onClick={() => {}}
                  />
                </div>
                {/* </Col> */}
              </Col>
              <Col md={4}>
                <h5 className={classes?.mainHeading}>Course Details</h5>
                <div
                  className={classes.dummyDiv}
                  // style={{
                  //   minHeight: height - 35,
                  // }}
                >
                 
                  <CourseCard
                    data={classData?.subscription?.course}
                    onClick={() => {
                      navigate(`/course/${classData?.subscription?.course?.slug}`)
                    }}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </SideBarSkeleton>

      {isLoading && <LottieLoader />}
      {show == "showUpload" && (
        <AddOrEditLessonMaterialModal
          show={show == "showUpload"}
          setShow={setShow}
          type={"pdf"}
          title={`${
            user?.role == "student" ? "Submit" : "Upload"
          } Quiz/Assignment`}
          handleSubmit={uploadOrSubmitAssignment}
          isLoading={isModalLoading == "upload"}
          isMultiple={false}
        />
      )}
      {show == "viewMaterial" && (
        <RenderMediaModal
          show={show == "viewMaterial"}
          setShow={setShow}
          type={"pdf"}
          hideToolBar={false}
          fileKey={assignments[0]?.postedDocument}
        />
      )}
      {show == "viewSubmission" && (
        <RenderMediaModal
          show={show == "viewSubmission"}
          setShow={setShow}
          type={"pdf"}
          hideToolBar={false}
          fileKey={assignments[0]?.submittedDocument}
        />
      )}
      {show == "mark-complete" && (
        <MarkAsCompletedModal
          show={show == "mark-complete"}
          setShow={setShow}
          isApiCall={isModalLoading == "mark-complete"}
          onSubmit={markAsCompleteClass}
        />
      )}
    </>
  );
};

export default ClassDetail;
