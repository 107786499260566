import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Get } from "../../Axios/AxiosFunctions";
import { Loader } from "../../Component/Loader";
import NoData from "../../Component/NoData/NoData";
import PaginationComponent from "../../Component/PaginationComponent";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import { BaseURL, recordsLimit } from "../../config/apiUrl";
import classes from "./Notifications.module.css";
import { MdNotifications } from "react-icons/md";

const NotificationBox = ({ item }) => {
  const navigate = useNavigate();
  return (
    <div
      className={[classes.HeaderNotificationInnerDiv]}
      onClick={()=>{ 
        item?.flag == "classRequest" &&
      navigate(`/class/request/${item?.classRequest?._id}`)
        item?.flag == "classesDetail" &&
      navigate(`/class/${item?.class?._id}`)
      item?.flag == "task" &&
      navigate(`/task/all`)
        item?.flag == "announcement" &&
      navigate(`/announcement/all`)
    }}
      data-seen={item?.seen}
    >
      <div className={[classes.ImgDiv]}>
        <MdNotifications size={40} />
      </div>
      <div className={[classes.contentDiv]}>
        <div className={[classes.titleDiv]}>
          <h6>{item?.title}</h6>
          <p>{moment(item?.createdAt).format("Do MMM YYYY hh:mm a")}</p>
        </div>
        <p>{item?.message}</p>
      </div>
      <div className={classes.flag}>
        {
          item?.flag=="classesDetail"?"Assignment":item?.flag=="classRequest"?"Class Request":item?.flag
        }
      </div>
    </div>
  );
};

const Notifications = () => {
  const { access_token: accessToken } = useSelector(
    (state) => state?.authReducer
  );
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);

  // get Data
  async function getData(pg = page) {
    const url = BaseURL(`users/notifications?page=${pg}&limit=${recordsLimit}`);
    setLoading(true);
    const response = await Get(url, accessToken);
    if (response) {
      setData(response?.data?.data);
      setTotalResults(response?.data?.totalCount);
    }
    setLoading(false);
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div>
        <SideBarSkeleton heading={"Notifications"}>
          <div className={classes.content}>
            <Container className={classes.main}>
              <Row className={classes.row}>
                {/* <Col md={12}>
                  <div className={classes.spBtw}>
                    <h6>Notifications</h6>
                  </div>
                </Col> */}
                <Col md={12}>
                  <div className={classes.notificationContainer}>
                    {loading ? (
                      <Loader className={classes.loader} />
                    ) : data?.length == 0 ? (
                      <NoData
                        text="No Notifications Found"
                        className={classes.loader}
                      />
                    ) : (
                      data?.map((item, key) => (
                        <NotificationBox item={item} key={key} />
                      ))
                    )}
                  </div>
                </Col>
                <Col md={12}>
                  <div className={classes.paginationDiv}>
                    {!!data?.length && totalResults > recordsLimit &&  (
                      <PaginationComponent
                        totalPages={Math.ceil(totalResults / recordsLimit)}
                        setCurrentPage={(e)=>{
                          setPage(e)
                          getData(e)
                        }}
                        currentPage={page}
                      />
                    )}
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </SideBarSkeleton>
      </div>
    </>
  );
};
export default Notifications;
