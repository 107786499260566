import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { GiHamburgerMenu } from "react-icons/gi";
import { AfterLoginHeader } from "../Header/AfterLoginHeader";
import SideBar from "../SideBar";
import Drawer from "react-modern-drawer";
import { isMobileViewHook } from "../../CustomHooks/isMobileViewHook";
import classes from "./SideBarSkeleton.module.css";
import { useLocation } from "react-router";

const SideBarSkeleton = ({ heading, children, headerClassName }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const paramsFound = searchParams?.get("roomData");

  useEffect(() => {
    isMobileViewHook(setIsMobile, 1025);
  }, [window.innerWidth]);

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };
  return (
    <>
      <style>{`
        .drawerContainer{
          width:320px !important;
        }
        @media (max-width:768px){
          .drawerContainer{
            width:290px !important;
          }
        }
    `}</style>
      <Container fluid className="g-0">
        <Row className="g-0">
          <div
            style={{
              display: paramsFound && "none",
            }}
            className={[!isMobile && classes.sidebarDiv].join(" ")}
          >
            {!isMobile ? (
              <SideBar />
            ) : (
              <Drawer
                open={isOpen}
                onClose={toggleDrawer}
                direction="left"
                className="drawerContainer"
              >
                <SideBar />
              </Drawer>
            )}
          </div>
          <div className={[!isMobile && classes.contentDiv].join(" ")}>
            {isMobile && !paramsFound && (
              <GiHamburgerMenu
                className={[classes.GiHamburgerMenu]}
                onClick={() => {
                  toggleDrawer();
                }}
              />
            )}
            <AfterLoginHeader heading={heading} className={headerClassName} />
            {children}
          </div>
        </Row>
      </Container>
    </>
  );
};

export default SideBarSkeleton;
