/* eslint-disable */

import React, { useEffect, useState } from "react";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import { Col, Container, Row } from "react-bootstrap";
import classes from "./AllCourses.module.css";
import { useNavigate } from "react-router-dom";
import { Get } from "../../Axios/AxiosFunctions";
import { useSelector } from "react-redux";
import LottieLoader from "../../Component/LottieLoader/LottieLoader";
import { BaseURL } from "../../config/apiUrl";
import { Button } from "../../Component/Button/Button";
import NoData from "../../Component/NoData/NoData";
import CourseCard from "../../Component/CourseCard/CourseCard";

const AllCourses = () => {
  const navigate = useNavigate();
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const [isLoading, setIsLoading] = useState(false);
  const [courseData, setCourseData] = useState(null);
  const url = BaseURL(`course`);
  const [category, setCategory] = useState("regular");

  // getCourseData
  const getCourseData = async () => {
    const apiUrl = `${url}/mycourses?category=${category}`;
    setIsLoading(true);
    const response = await Get(apiUrl, accessToken);

    if (response !== undefined) {
      setCourseData(response?.data?.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getCourseData();
  }, [category]);

  return (
    <div>
      <SideBarSkeleton heading={"All Courses"}>
        <div className={classes?.topContainer}>
          <Container className={classes.main}>
            <Row className={classes.row}>
              <Col md={12} className={classes?.headerContainer}>
                <div className={classes?.btnContainer}>
                  <div title="Get Regular Courses">
                    <Button
                      className={[
                        classes.acceptBtn,
                        category !== "regular" && classes?.disabled,
                      ].join(" ")}
                      label={"Regular Course"}
                      onClick={() => {
                        setCategory("regular");
                      }}
                    />
                  </div>
                  <div title="Get Additional Courses">
                    <Button
                      className={[
                        classes.acceptBtn,
                        category !== "additional" && classes?.disabled,
                      ].join(" ")}
                      label={"Additional Course"}
                      onClick={() => {
                        setCategory("additional");
                      }}
                    />
                  </div>
                  <div title="Get Other Course">
                    <Button
                      className={[
                        classes.acceptBtn,
                        category !== "other" && classes?.disabled,
                      ].join(" ")}
                      label={"Other Course"}
                      onClick={() => {
                        setCategory("other");
                      }}
                    />
                  </div>
                </div>
              </Col>
              {courseData?.length > 0 ? (
                courseData?.map((item, index) => (
                  <Col md={4} key={`courses-${index}`}>
                    <CourseCard
                      data={item}
                      onClick={() => {
                        navigate(`/course/${item?.slug}`);
                      }}
                    />
                  </Col>
                ))
              ) : (
                !isLoading && <NoData text={"No Courses Found"} />
              )}
             
            </Row>
          </Container>
        </div>
      </SideBarSkeleton>

      {isLoading && <LottieLoader />}
    </div>
  );
};

export default AllCourses;
