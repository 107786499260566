/* eslint-disable */

import React, { useRef } from "react";
import { RiDeleteBinLine } from "react-icons/ri";
import classes from "./UploadVideoBox.module.css";
import { Button } from "../Button/Button";
import { toast } from "react-toastify";
import { CgPlayButtonO } from "react-icons/cg";
import { UploadVideoValidtor } from "../../constant/commonFunctions";

function UploadVideoBox({ state, setter, edit = true, isMultiple = true }) {
  const inputRef = useRef(null);
  const HandleUploadFile = async (e) => {
    if (isMultiple) {
      let notVideo = false;
      const files = Array.from(e.target.files);
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (!UploadVideoValidtor(file)) {
          notVideo = true;
          break;
        }
      }
      if (notVideo) {
        return toast.error("Invalid Video");
      }
      setter((prev) => [...prev, ...files]);
    } else {
      const file = e.target.files[0];
      if (!UploadVideoValidtor(file)) {
        return toast.error("Invalid Video");
      }
      setter(file);
    }
  };

  const HandleDeleteFile = (index) => {
    if (isMultiple) {
      setter((prev) => prev.filter((_, i) => i !== index));
    } else {
      setter(null);
    }
  };

  return (
    <div className={classes.box}>
      {(isMultiple || state == null) && (
        <div
          className={classes.uploadBox}
          onClick={() => inputRef.current.click()}
        >
          <CgPlayButtonO className={classes.uploadIcon} />
        </div>
      )}
      {Array.isArray(state)
        ? state.map((file, index) => (
            <div className={classes.uploadBox} key={index}>
              <video controls>
                <source src={URL.createObjectURL(file)} type={file.type} />
              </video>
              {edit && (
                <div className={classes.editAndDelete}>
                  <Button
                    className={classes.icon}
                    onClick={() => HandleDeleteFile(index)}
                    leftIcon={<RiDeleteBinLine />}
                  />
                </div>
              )}
            </div>
          ))
        : state && (
            <div className={classes.uploadBox}>
              <video controls>
                <source src={URL.createObjectURL(state)} type={state.type} />
              </video>
              {edit && (
                <div className={classes.editAndDelete}>
                  <Button
                    className={classes.icon}
                    onClick={() => HandleDeleteFile(index)}
                    leftIcon={<RiDeleteBinLine />}
                  />
                </div>
              )}
            </div>
          )}

      {/* Input For File Upload */}
      <input
        hidden
        type="file"
        ref={inputRef}
        onChange={HandleUploadFile}
        multiple={isMultiple}
        accept="video/*"
      />
    </div>
  );
}

export default UploadVideoBox;
