/* eslint-disable */

import React, { useEffect, useState } from "react";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import { Col, Container, Row } from "react-bootstrap";
import classes from "./LessonLearningMaterial.module.css";
import { useLocation, useParams } from "react-router-dom";
import { Get } from "../../Axios/AxiosFunctions";
import { useSelector } from "react-redux";
import LottieLoader from "../../Component/LottieLoader/LottieLoader";
import { BaseURL } from "../../config/apiUrl";
import { Button } from "../../Component/Button/Button";
import NoData from "../../Component/NoData/NoData";
import MaterialCard from "../../Component/MaterialCard/MaterialCard";

const LessonLearningMaterial = () => {
  const data = useLocation()?.state;
  const { courseSlug } = useParams();
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const [isLoading, setIsLoading] = useState(false);
  const [materialData, setMaterialData] = useState(data || null);
  const url = BaseURL(`course`);
  const [materialType, setMaterialType] = useState("pdf");

  // getLessonMaterial
  const getLessonMaterial = async () => {
    const apiUrl = `${url}/lesson/${courseSlug}`;
    setIsLoading(true);
    const response = await Get(apiUrl, accessToken);

    if (response !== undefined) {
      setMaterialData(response?.data?.data?.material);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (data == null) getLessonMaterial();
  }, []);

  return (
    <div>
      <SideBarSkeleton heading={"Lesson Material"}>
        <div className={classes?.topContainer}>
          <Container className={classes.main}>
            <Row>
              <Col md={12} className={classes?.headerContainer}>
                {/* <h5 className={classes?.mainHeading}>Lesson Material</h5> */}
                <div className={classes?.btnContainer}>
                  <div title="All PDF Learning Material">
                    <Button
                      className={[
                        classes.acceptBtn,
                        materialType !== "pdf" && classes?.disabled,
                      ].join(" ")}
                      label={"PDF"}
                      onClick={() => {
                        setMaterialType("pdf");
                      }}
                    />
                  </div>
                  <div title="All Videos Learning Material">
                    <Button
                      className={[
                        classes.acceptBtn,
                        materialType !== "video" && classes?.disabled,
                      ].join(" ")}
                      label={"Videos"}
                      onClick={() => {
                        setMaterialType("video");
                      }}
                    />
                  </div>
                  <div title="All Images Learning Material">
                    <Button
                      className={[
                        classes.acceptBtn,
                        materialType !== "image" && classes?.disabled,
                      ].join(" ")}
                      label={"Images"}
                      onClick={() => {
                        setMaterialType("image");
                      }}
                    />
                  </div>
                </div>
              </Col>
              {materialData?.length > 0 &&
                materialData?.map(
                  (item, index) =>
                    item?.type == materialType && (
                      <Col md={4} key={`lessson-${index}`}>
                        <MaterialCard data={item} />
                      </Col>
                    )
                )}
              {materialData.filter((e) => e?.type == materialType)?.length ==
                0 && <NoData text={"No Lesson Material Found"} />}
            </Row>
          </Container>
        </div>
      </SideBarSkeleton>

      {isLoading && <LottieLoader />}
    </div>
  );
};

export default LessonLearningMaterial;
